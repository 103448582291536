import React from "react";

import {
  Card,
  CardContent,
  CellIcon,
  TextBox,
  Cell,
} from "@sberdevices/plasma-ui";
import { detectDevice } from "@sberdevices/plasma-ui";

import { StolotoTabs } from "../../StolotoTabs";

import { GAMES } from "../../constants";

import { sendText } from "../../../helpers/Assistant";
import { AssistantContext } from "../../../helpers/AssistantContext";
import { isFullHD, isSmallScreen, isTouch } from "../../../helpers/ScreenUtils";

import { PayPopupImg, GamesStyledCard } from "../../../style/style";
import { StyledCarousel, StyledCarouselItem } from "../../../style/style";
import {
  MyGamesStyledCarouselItem,
  MyGamesContainer,
  NameGameOnMyGames,
} from "./styles/offline-tickets-styles";
class MyGames extends React.Component {
  static contextType = AssistantContext;

  constructor(props) {
    super(props);
    this.state = {
      carouselIndex: 0,
    };
  }

  componentDidMount() {
    this.setPageOverflow("scroll");
    this.initialFocus?.focus();
  }

  componentWillUnmount() {
    this.setPageOverflow("hidden");
  }

  setPageOverflow(overflowValue) {
    if (detectDevice() == "mobile") {
      document.getElementsByTagName("body")[0].style.overflowY = overflowValue;
      document.getElementsByTagName("html")[0].style.overflowY = overflowValue;
      window.scrollTo(0, 0);
    }
  }

  getGames(game, index) {
    let tr = [];
    game.map((gameItem, key) =>
      tr.push(
        <AssistantContext.Consumer>
          {(assistant) => (
            <StyledCarouselItem
              style={{ paddingBottom: "7px", background: "#000000" }}
              key={key}
            >
              <GamesStyledCard
                isMyGames={true}
                ref={(card) => {
                  if (!this.initialFocus && key == 0) {
                    this.initialFocus = card;
                  }
                }}
                tabIndex={this.props.messagePopupWasOpened ? -1 : 0}
                style={{ background: "#000000" }}
                onClick={(e) => isTouch() && sendText(assistant, gameItem.id)}
                onKeyUp={(e) => {
                  e.key === "Enter" && sendText(assistant, gameItem.id);
                }}
              >
                <CardContent style={{ padding: "0px" }} compact>
                  <Cell
                    contentLeft={
                      <CellIcon style={{ display: "contents" }}>
                        <PayPopupImg
                          src={
                            process.env.PUBLIC_URL +
                            "/mini/" +
                            gameItem.gameLogo
                          }
                        />
                      </CellIcon>
                    }
                    content={
                      <TextBox style={{ paddingTop: "0px" }}>
                        <NameGameOnMyGames style={{ color: "white" }}>
                          {gameItem.rusName}
                        </NameGameOnMyGames>
                      </TextBox>
                    }
                  />
                </CardContent>
              </GamesStyledCard>
            </StyledCarouselItem>
          )}
        </AssistantContext.Consumer>
      )
    );
    return tr;
  }
  getPaddingStart() {
    if (isFullHD()) {
      return "4.6%";
    }
    if (isSmallScreen()) {
      return "4%";
    }
    if (detectDevice() == "mobile") {
      return "1%";
    }
  }
  render() {
    const chunkSize =
      window.innerWidth > 1281 && window.innerWidth < 1440 ? 4 : 6;
    let newArray = [];
    for (let i = 0; i < GAMES.length; i += chunkSize) {
      const chunk = GAMES.slice(i, i + chunkSize);
      newArray.push(chunk);
    }
    return (
      <>
        <StolotoTabs
          messagePopupWasOpened={this.props.messagePopupWasOpened}
          activeTab="check_tickets"
          messageShow={this.props.messageShow}
        />

        <MyGamesContainer>
          {
            <StyledCarousel
              style={{ background: "#000000", paddingTop: "30px" }}
              paddingStart={this.getPaddingStart()}
              paddingEnd={detectDevice() != "mobile" ? "25%" : "100px"}
              index={this.state.carouselIndex}
              tabIndex={this.props.messageShow ? -1 : 0}
              axis={detectDevice() == "mobile" ? "y" : "x"}
            >
              {newArray.map((game, i) => (
                <MyGamesStyledCarouselItem key={i}>
                  <Card
                    style={{ background: "#000000" }}
                    key={i}
                    onFocus={(e) => {
                      this.setState({ carouselIndex: i });
                    }}
                  >
                    {this.getGames(game, i)}
                  </Card>
                </MyGamesStyledCarouselItem>
              ))}
            </StyledCarousel>
          }
        </MyGamesContainer>
      </>
    );
  }
}

export default MyGames;
