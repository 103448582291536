import styled from "styled-components";

import {
  Container,
  Row,
  Col,
  Button,
  ParagraphText1,
  Footnote1,
} from "@sberdevices/plasma-ui";

export const AuthPageMainContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10%;
  @media (min-width: 1441px) and (max-width: 1961px) {
    margin-top: 135px;
    // margin-left: 10em;
  }
  @media (min-width: 1281px) and (max-width: 1440px) {
    margin-top: 30px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    margin-top: 2em;
    //margin-left: 3.5em;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    height: 100%;
    //margin-left: 1em;
    padding-top: 111px;
  }
`;

export const AuthPageContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1281px) and (max-width: 1961px) {
    width: 1100px;
    height: 540px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    width: 960px;
    height: 390px;
  }
`;

export const AuthPageMessageContainer = styled.div`
  z-index: 5;
  position: fixed;
  width: 100%;
  height: 100%;

  margin: -1em;
  display: grid;
  background-color: rgba(0, 0, 0, 0.6);
  @media (min-width: 1281px) and (max-width: 1961px) {
    top: 1em;
    left: 1em;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    top: 0em;
    left: 2em;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    top: 1em;
    left: 1em;
  }
`;

export const AuthPageContent = styled.div`
  grid-area: content;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #1a1a1a;
`;

export const AuthPageParagraphText1Main = styled(ParagraphText1)`
  font-weight: 400;
  @media (min-width: 1281px) and (max-width: 1961px) {
    font-size: 26px;
    margin-top: 42px;
    width: 305px;
    line-height: 32px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    font-size: 24px;
    margin-top: 42px;
    width: 280px;
    line-height: 32px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    font-size: 12px;
    margin-top: 80px;
    text-align: center;
    width: 200px;
  }
`;

export const AuthPageParagraphText1Policy = styled(Footnote1)`
  font-weight: 400;

  @media (min-width: 1281px) and (max-width: 1961px) {
    font-size: 26px;
    margin-top: 42px;
    width: 400px;
    line-height: 32px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    font-size: 20px;
    margin-top: 32px;
    width: 320px;
    line-height: 32px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    font-size: 12px;
    margin-top: 80px;
    text-align: center;
    width: 200px;
  }
`;

export const AuthPageTextCol = styled(Col)`
  @media (min-width: 1281px) and (max-width: 1961px) {
    margin-left: 1em;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    margin-left: 1em;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
`;

export const AuthPageTextFieldRow = styled(Row)`
  width: 100%;
  @media (min-width: 1281px) and (max-width: 1961px) {
    margin-bottom: ${(props) => (props.isFirstField ? "20px" : "42px")};
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    margin-bottom: ${(props) => (props.isFirstField ? "20px" : "42px")};
  }
  @media (min-width: 140px) and (max-width: 960px) {
    margin-bottom: ${(props) => (props.isFirstField ? "10px" : "20px")};
  }
`;

export const AuthPageCol = styled(Col)`
  @media (min-width: 1281px) and (max-width: 1961px) {
    width: 532px;
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 175px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    width: 514px;

    padding-left: 0px;
    padding-right: 0px;
    margin-right: 84px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    width: 312px;
  }
`;
export const AuthPageImgQr = styled.img`
  @media (min-width: 1281px) and (max-width: 1961px) {
    width: 290px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    width: 222px;
  }
`;

export const AuthPageWrongPswd = styled(ParagraphText1)`
  font-size: 24px;
  color: #dc283a;
  @media (min-width: 140px) and (max-width: 960px) {
    font-size: 12px;
    padding-left: 16px;
    padding-top: 5px;
  }
`;
export const AuthPageButtonLogin = styled(Button)`
  width: 100%;
  transform: none !important;
  @media (min-width: 1281px) and (max-width: 1961px) {
    //border: 5px solid black;
    margin-left: 0px;
  }
`;

export const AuthPageButtonForgotPswd = styled(Button)`
  width: 100%;
  //margin-left: 20px;
  // margin-top: 15px;
  background: #1f1f1f;
  @media (min-width: 1281px) and (max-width: 1961px) {
    margin-left: 0px;
    margin-top: 20px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    // width: 330px;
    // height: 52px;
    font-size: 16px;
    margin-top: 20px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    // width: 230px;
    // height: 32px;
    font-size: 14px;
    margin-top: 10px;
  }
`;

export const AuthPagePayPopupCrossStyles = styled.span`
  font-size: 1.5em;
  cursor: pointer;
  position: absolute;
  z-index: 7;
  @media (min-width: 1281px) and (max-width: 1961px) {
    right: 8.9em;
    top: 5.5em;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    right: 2.8em;
    top: 4.2em;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    right: 1em;
    top: 9.5em;
  }
`;

export const AuthPageButtonUnderstand = styled(Button)`
  background: #12a557;
  @media (min-width: 1281px) and (max-width: 1961px) {
    width: 280px;
    height: 80px;

    border: 5px solid black;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    width: 280px;
    height: 80px;
  }
`;

export const AuthPagePayPopupImg = styled.img`
  @media (min-width: 1281px) and (max-width: 1961px) {
    width: 240px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    width: 200px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    width: 100%;
  }
`;

export const AuthPagePayPopupRow = styled(Row)`
  @media (min-width: 1281px) and (max-width: 1961px) {
    margin-top: 3em;
    width: 100%;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    margin-top: 1.7em;
    width: 100%;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    margin-top: 1em;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const AuthPagePayPopupParagraphText1 = styled(ParagraphText1)`
  font-weight: 600;
  width: 100%;
  text-align: left;
  @media (min-width: 1281px) and (max-width: 1961px) {
    margin-top: 42px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    margin-top: 50px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    margin-top: 85px;
    text-align: center;
  }
`;

export const ButtonLink = styled.button`
  font-weight: 400;
  font-size: 26px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;

    .focusText {
      outline: 1px solid #fff;
    }
  }
`;

export const AuthPageInputWrapper = styled.div`
  @media (min-width: 961px) and (max-width: 1441px) {
    input {
      font-size: 24px;
      height: 64px;
      padding: 18px;
      border-radius: 24px;
    }
  }
`;

export const AuthPageButtonWrapper = styled.div`
  width: 100%;

  @media (min-width: 961px) and (max-width: 1441px) {
    button {
      font-size: 20px;
      height: 64px;
      padding: 18px;
    }
  }
`;
