import styled from "styled-components";

import {
  Container,
  Row,
  Col,
  Card,
  CarouselItem,
  Carousel,
  CardContent,
  TextBoxBigTitle,
  Button,
  CarouselCol,
  TabItem,
  Tabs,
} from "@sberdevices/plasma-ui";
import { ParagraphText2 } from "@sberdevices/plasma-ui";

export const MainContainer = styled.div`
  .bingo-wrapper {
    @media (min-width: 1279px) and (max-width: 1961px) {
      display: grid;
      grid-template-columns: 33vw 67vw;
    }
  }

  .loto-wrapper {
    @media (min-width: 1279px) and (max-width: 1961px) {
      display: grid;
      grid-template-columns: 33vw 67vw;
    }
  }
`;

export const CardContentButtons = styled(CardContent)`
  padding: 0px;
`;
export const TextBoxBigTitleMini = styled(TextBoxBigTitle)`
  font-size: 16px;
`;

export const ButtonContainer = styled(Container)`
  display: contents;
`;

export const ActionButton = styled(Button)`
  margin-bottom: 1em;
  font-size: 14px;
  @media (max-width: 960px) {
    width: 232px;
  }
  @media (max-width: 640px) {
    width: 181px;
  }
  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const RightContainer = styled(Col)`
  display: ${(props) => props.isWaiting && "flex"};
  align-items: ${(props) => props.isWaiting && "center"};
  justify-content: ${(props) => props.isWaiting && "space-around"};

  @media (min-width: 1441px) and (max-width: 1920px) {
    margin-left: 0em;
    margin-top: ${(props) => props.specialMargin};
  }
  @media (min-width: 1281px) and (max-width: 1440px) {
    margin-top: 0;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    margin-top: ${(props) => props.specialMargin};
  }
  @media (min-width: 800px) and (max-width: 961px) {
    margin-left: 0em;
  }
  @media (max-width: 450px) {
    margin-top: 1em;
  }
`;

export const StyledParagraphText2 = styled(ParagraphText2)`
  @media (min-width: 961px) and (max-width: 1961px) {
    font-size: 16px;
  }
  @media (min-width: 800px) and (max-width: 961px) {
    font-size: 10px;
  }

  @media (min-width: 640px) and (max-width: 800px) {
    font-size: 10px;
  }
  @media (max-width: 450px) {
    font-size: 10px;
  }
`;

export const StyledCarouselCol = styled(CarouselCol)`
  @media (min-width: 960px) {
    margin-left: 1em;
  }

  @media (min-width: 640px) and (max-width: 800px) {
    margin-left: 1em;
  }
`;
export const ButtonsRow = styled(Row)`
  @media (min-width: 1441px) and (max-width: 1961px) {
    margin-top: 3em;
  }
  @media (min-width: 1281px) and (max-width: 1440px) {
    margin-top: 0;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    margin-top: 18px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
  }
`;

export const LeftContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  z-index: 3;

  @media (min-width: 1441px) and (max-width: 1961px) {
    width: 460px;
    margin-left: 127px;
    margin-top: 33px;
  }
  @media (min-width: 1281px) and (max-width: 1440px) {
    width: 400px;
    margin-left: 85px;
    margin-top: 25px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    width: 370px;
    margin-top: 77px;
    margin-left: 75px;
    margin-righ: 0px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    margin-left: 0px;
    margin-right: 0px;
  }
`;

export const LeftShadow = styled.div`
  position: fixed;
  height: 100%;
  top: 50px;
  background: #000000;
  filter: blur(30px);
  z-index: 2;
  opacity: 0.9;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  @media (min-width: 1441px) and (max-width: 1961px) {
    width: 171px;
    margin-left: 472px;
  }
  @media (min-width: 1281px) and (max-width: 1440px) {
    margin-left: 350px;
    width: 170px;
    margin-top: 120px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    width: 135px;
    margin-left: 370px;
    margin-top: 2.3em;
  }
  @media (min-width: 140px) and (max-width: 960px) {
  }
`;

export const PayPopupTextBoxBigTitle = styled(TextBoxBigTitle)`
  @media (min-width: 1281px) and (max-width: 1961px) {
    font-size: 48px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    font-size: 40px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    font-size: 30px;
  }
`;

export const PayPopupImg = styled.img`
  border-radius: 50%;
  margin-right: 0.5em;
  @media (min-width: 1281px) and (max-width: 1961px) {
    height: 80px;
    width: 80px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    height: 56px;
    width: 56px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    height: 33px;
    width: 33px;
  }
`;

export const ActivityButton = styled(Button)`
  font-weight: 500;
  visibility: ${(props) => props.disableButton && "hidden"};
  background: ${(props) => props.disableButton && "rgb(12, 12, 12) !important"};
  opacity: 1;
  margin-top: 10px;
  @media (min-width: 1441px) and (max-width: 1961px) {
    font-size: ${(props) => (props.isPayButton ? "24px" : "29px")};
    margin-top: 22px;
    width: 464px;
  }
  @media (min-width: 1281px) and (max-width: 1440px) {
    font-size: 24px;
    width: 400px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    font-size: ${(props) => (props.isPayButton ? "19px" : "24px")};
    margin-top: 16px;
    width: 362px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    font-size: ${(props) => (props.generateNew ? "15px" : "14px")};
    margin-top: 10px;
    width: 312px;
  }
`;

export const RightShadow = styled.div`
  width: 2%;
  margin-left: -3%;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  background: black;
  filter: blur(30px);
`;

export const CarouselItemCardContent = styled(Card)`
  margin-right: 0.5em;
  margin-bottom: 1em;
  margin-left: 0.5em;
`;

export const StyledCarousel = styled(Carousel)`
  box-sizing: border-box;
  padding-top: 8px;
  padding-bottom: 8px;
  outline: none;
  scroll-snap-type: none;
  scroll-behavior: smooth;
`;

export const StyledCarouselItem = styled(CarouselItem)`
  padding-bottom: 7px;
  padding-left: ${(props) => !props.isZeroPadding && "7px"};
  &:last-child {
    padding-right: 0;
  }

  @media (min-width: 1281px) and (max-width: 1961px) {
    border-bottom: ${(props) =>
      props.isKeno && "1px solid rgba(255,255,255,0.2)"};
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    padding-bottom: 0px;
    padding-left: ${(props) =>
      props.isTop3Game || props.isZeroPadding ? "0px" : "13px"};
    padding-top: ${(props) => (props.isTop3Game || props.isKeno) && "5px"};
    padding-right: ${(props) => props.isZeroPadding && "0px"};
    border-bottom: ${(props) =>
      props.isKeno && "1px solid rgba(255,255,255,0.2)"};
  }
  @media (min-width: 140px) and (max-width: 960px) {
    padding-bottom: 1px;
  }
`;

export const Square9x3 = styled(Button)`
  font-size: 12px;
`;

export const GamesStyledCard = styled(Card)`
  background: #000000;
  @media (min-width: 1281px) and (max-width: 1961px) {
    width: 13em;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    width: 14em;
    padding-bottom: ${(props) => !props.isMyGames && "25px"};
  }
  @media (min-width: 140px) and (max-width: 960px) {
    width: 100%;
  }
`;

export const GamesStyledContainer = styled(Container)`
  @media (min-width: 1441px) and (max-width: 1961px) {
    height: 35em;
    padding-left: 0px;
    padding-right: 0px;
  }
  @media (min-width: 1281px) and (max-width: 1440px) {
    margin-top: 5vh;
    height: 100vh;
    padding-left: 0px;
    padding-right: 0px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    height: 100vh;
    padding-left: 0px;
    padding-right: 0px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 40px;
    height: 100vh;
  }
`;

export const StolotoTabsTabItem = styled(TabItem)`
  @media (min-width: 1281px) and (max-width: 1961px) {
    font-size: 28px;
    height: 72px;
    max-width: 350px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    font-size: 18px;
    height: 48px;
    max-width: 235px;
    padding-right: 35px;
    padding-left: 35px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    height: 48px;
    width: 230px;
  }
`;

export const StolotoTabsImageAppName = styled.img`
  @media (min-width: 1281px) and (max-width: 1961px) {
    height: 84px;
    width: 268px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    height: 56px;
    width: 180px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    height: 90px;
    width: 350px;
  }
`;

export const StolotoTabsImageLogout = styled.img`
  @media (min-width: 1281px) and (max-width: 1961px) {
    height: 48px;
    width: 48px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    height: 32px;
    width: 32px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    height: 24px;
    width: 24px;
  }
`;

export const StolotoTabsList = styled(Tabs)`
  width: 100%;
  background-color: black;
  @media (min-width: 1281px) and (max-width: 1961px) {
    height: 160px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    height: 103px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    height: 53px;
  }
`;

export const MessageContainerDraw = styled.div`
  z-index: 5;
  border-radius: 20px;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  //margin: -1em;
  display: grid;
  background-color: rgba(0, 0, 0, 0.6);
  justify-items: center;
  align-items: center;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  -webkit-align-items: center;
`;

export const MessageDrawContent = styled.div`
  transition: none;
  box-shadow: none;
  border-radius: 20px;
  grid-area: content;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #1a1a1a;
  background: #1a1a1a;
  border-radius: 20px;
  flex-direction: row-reverse;
  align-items: stretch;
  // padding-left: 10px;
  // padding-right: 10px;
`;

export const ArrowButtonStyles = styled(Button)`
  width: 25px;
  height: 25px;
  margin-right: 20px;
  @media (min-width: 1281px) and (max-width: 1961px) {
    width: 50px;
    height: 50px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    padding-left: inherit;
    padding-right: inherit;
    width: 50px;
    height: 50px;
    margin-right: 25px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    width: 32px;
    height: 32px;
    padding-left: inherit;
    padding-right: inherit;
    margin-right: 20px;
    border-radius: 20px;
  }
`;
