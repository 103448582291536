import styled from "styled-components";

import { TabItem, Tabs, Row } from "@sberdevices/plasma-ui";

export const StolotoTabsTabItemsTextContentWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  @media (min-width: 1281px) and (max-width: 1961px) {
  }

  @media (min-width: 961px) and (max-width: 1281px) {
    // padding-left: 237px;
  }

  @media (min-width: 140px) and (max-width: 960px) {
  }
`;

export const StolotoTabsTabItem = styled(TabItem)<{ imageLogout?: boolean }>`
  @media (min-width: 1441px) and (max-width: 1961px) {
    font-size: 24px;
    height: 72px;
    margin-top: 35px;
    margin-right: 10px;
    max-width: ${(props) => (props.imageLogout ? "150px" : "350px")};
    //margin-right: 25px;
  }

  @media (min-width: 1281px) and (max-width: 1440px) {
    height: 56px;
    font-size: 20px;
    max-width: ${(props) => (props.imageLogout ? "150px" : "290px")};
    margin-top: 20px;
    margin-right: 10px;
  }

  @media (min-width: 961px) and (max-width: 1281px) {
    font-size: 18px;
    height: 48px;
    max-width: 235px;
    margin-top: 35px;
    padding-right: 35px;
    padding-left: 35px;
  }

  @media (min-width: 140px) and (max-width: 960px) {
    height: 48px;
    width: 230px;
  }
`;

export const StolotoTabsMainRow = styled(Row)<{ position: string }>`
  width: 100%;
  position: ${(props) => props.position};
  top: 0;
  z-index: 1;
  border-radius: 0px;
`;

export const StolotoTabsImageAppName = styled.img`
  @media (min-width: 1281px) and (max-width: 1961px) {
    margin-top: 35px;
    padding-left: 105px;
    height: 84px;
    width: 268px;
  }

  @media (min-width: 1281px) and (max-width: 1440px) {
    margin-top: 30px;
    padding-left: 95px;
    height: 65px;
    width: 215px;
  }

  @media (min-width: 961px) and (max-width: 1281px) {
    height: 56px;
    width: 180px;
    margin-top: 35px;
    padding-left: 78px;
  }

  @media (min-width: 140px) and (max-width: 960px) {
    height: 90px;
    width: 350px;
  }
`;

export const StolotoTabsImageLogout = styled.img`
  @media (min-width: 1441px) and (max-width: 1961px) {
    padding-right: 128px;
    height: 48px;
    width: 48px;
  }

  @media (min-width: 1281px) and (max-width: 1440px) {
    padding-right: 96px;
    height: 38px;
    width: 38px;
  }

  @media (min-width: 961px) and (max-width: 1281px) {
    height: 32px;
    width: 32px;
    padding-right: 58px;
  }

  @media (min-width: 140px) and (max-width: 960px) {
    height: 24px;
    width: 24px;
  }
`;

export const StolotoTabsList = styled(Tabs)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: black;
  border-radius: 0px;

  @media (min-width: 1281px) and (max-width: 1961px) {
    height: 160px;
  }

  @media (min-width: 961px) and (max-width: 1281px) {
    height: 103px;
  }

  @media (min-width: 140px) and (max-width: 960px) {
    height: 53px;
  }
`;

export const StolotoFocusButton = styled.button`
  pointer-events: none;
  width: 100%;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
`;
