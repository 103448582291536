import React from "react";

import { Row, TextBox, detectDevice, Col } from "@sberdevices/plasma-ui";
import { IconChevronRight, IconChevronLeft } from "@sberdevices/plasma-icons";

import Square from "./pieces/Square";
import Top3GameSettingsControlPanel from "./pieces/Top3GameSettingsControlPanel";
import KenoFirstGame from "./pieces/KenoFirstGame";
import KenoThirdGame from "./pieces/KenoThirdGame";

import {
  detectDeviceSize,
  isFullHD,
  isSmallScreen,
  isTouch,
} from "../../../helpers/ScreenUtils";

import { StyledCarouselItem, ArrowButtonStyles } from "../../../style/style";
import {
  LottoTicketControllerMainRow,
  LottoTicketsControllerTextBoxBigTitle,
  LottoTicketsControllerTextBoxBigTitleFirstField,
  LottoTicketsControllerStyledCarousel,
  LottoTicketsControllerStyledCarouselItem,
  LottoTicketsControllerTextBoxSubTitle,
  LottoTicketsControllerTextBoxBigTitleSquare,
  LottoTicketsControllerTextBoxSubTitleRule,
  LottoTicketsControllerCard,
  LottoTicketsControllerRow,
  LottoTicketsControllerTextBoxFirstTicket,
  LottoTicketsControllerCol,
  SecondTicketRow,
  LottoTicketsCol,
  LottoTicketsControllerTextBoxSecondTicket,
  LottoKenoThirdGameRow,
  LottoTicketControllerRowHeader,
  LottoTicketsControllerTextBoxSubTitleRuleKeno,
  LottoTicketsColTop3,
  LottoTicketsControllerTextBoxSubTitleRuleRow,
} from "./styles/lotto-ticket-styles";
class LottoTicketsController extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      carouselIndex: 0,
    };
  }
  setLottoTicket = (
    currentNumber,
    ticketNumber,
    colNum,
    rowNum,
    ticketType
  ) => {
    if (!this.props?.ticketWaiting) {
      if (this.props.gameName === "top3") {
        this.props.setTicketNumber(currentNumber, ticketNumber, colNum, rowNum);
      } else {
        this.props.setTicketNumber(currentNumber, ticketNumber, ticketType);
      }
    }
  };

  getSquareColor(selectedNumbers, currentNumber, rowNum, colNum) {
    let color = {};
    color =
      this.props.gameName !== "top3"
        ? selectedNumbers.has(currentNumber)
          ? this.props?.ticketWaiting
            ? "success"
            : "checked"
          : "secondary"
        : this.props.ticketInfo["selectedNumbersFirstTicket"].get(colNum)
            ?.rowNumber == rowNum
        ? this.props?.ticketWaiting
          ? "success"
          : "checked"
        : "secondary";
    return color;
  }

  isDisplayNone(selectedNumbers, currentNumber, maxSquareViewed) {
    return this.props.gameName !== "top3"
      ? currentNumber <= maxSquareViewed
        ? selectedNumbers.has(currentNumber)
          ? "block"
          : "block"
        : "none"
      : "block";
  }

  isSquareActive(currenTicketProps, colNum, selectedNumbers, curNumber) {
    return !(
      this.props.gameName == "top3" &&
      currenTicketProps["colsLimitation"].includes(colNum) &&
      !selectedNumbers.includes(curNumber)
    );
  }

  getColor(selectedNumbers, currentNumber, colNum, rowNum, isSquareActive) {
    return this.props?.ticketWaiting
      ? "white"
      : this.props.gameName != "top3"
      ? selectedNumbers.has(currentNumber)
        ? "black"
        : "white"
      : this.props.ticketInfo["selectedNumbersFirstTicket"].get(colNum)
          ?.rowNumber == rowNum
      ? "black"
      : isSquareActive
      ? "white"
      : "gray";
  }

  getGameSquare(
    isFarLeftSquare,
    ticketKey,
    currentNumber,
    ticketType,
    ticketNumber,
    colNum,
    rowNum,
    maxSquareViewed,
    selectedNumbers,
    currenTicketProps,
    curNumber,
    length
  ) {
    const squareColor = this.getSquareColor(
      selectedNumbers,
      currentNumber,
      rowNum,
      colNum
    );
    const isDisplayNone = this.isDisplayNone(
      selectedNumbers,
      currentNumber,
      maxSquareViewed
    );
    const isSquareActive = this.isSquareActive(
      currenTicketProps,
      colNum,
      selectedNumbers,
      curNumber
    );
    const color = this.getColor(
      selectedNumbers,
      currentNumber,
      colNum,
      rowNum,
      isSquareActive
    );
    return (
      <Square
        key={rowNum + colNum + length}
        messagePopupWasOpened={this.props.messagePopupWasOpened}
        isSquareActive={isSquareActive}
        isDisplayNone={isDisplayNone}
        squareColor={squareColor}
        isFarLeftSquare={isFarLeftSquare}
        ticketWaiting={this.props?.ticketWaiting}
        gameName={this.props.gameName}
        currentNumber={currentNumber}
        //maxSquareViewed={maxSquareViewed}
        //selectedNumbers={selectedNumbers}
        //currenTicketProps={currenTicketProps}
        //curNumber={curNumber}
        colNum={colNum}
        rowNum={rowNum}
        ticketType={ticketType}
        ticketNumber={ticketNumber}
        // ticketInfo={this.props.ticketInfo}
        getIndexOnPressButton={this.props.getIndexOnPressButton}
        ticketKey={ticketKey}
        setNumber={this.setLottoTicket}
        color={color}
        messageShow={this.props.messageShow}
        waitResult={this.props.waitResult}
      />
    );
  }

  ticketConstructor(ticketKey, ticketNumber, ticketType) {
    let tr = [];
    const currenTicketProps = this.props.rules;
    const rows =
      ticketType === "mainTicket"
        ? this.props.rowsFirstPlayingField
        : this.props.rowsSecondPlayingField;
    const cols =
      ticketType === "mainTicket"
        ? this.props.colsFirstPlayingField
        : this.props.colsSecondPlayingField;
    const maxSquareViewed =
      ticketType === "mainTicket"
        ? this.props.maxSquareViewedFirstPlayingField
        : this.props.maxSquareViewedSecondPlayingField;
    const selectedNumbers =
      ticketType === "mainTicket"
        ? this.props.selectedNumbersFirstPlayingField
        : this.props.selectedNumbersSecondPlayingField;
    for (let i = 0; i < rows; i++) {
      let td = [];
      for (let j = 0; j < cols; j++) {
        const currentNumber =
          this.props.gameName !== "top3" ? j + 1 + i * cols : 9 - i;
        const curNumber = j + 1 + i * cols;
        const isFarLeftSquare = j == 0 && isTouch();
        const currentSquare = this.getGameSquare(
          isFarLeftSquare,
          ticketKey,
          currentNumber,
          ticketType,
          ticketNumber,
          j,
          i,
          maxSquareViewed,
          selectedNumbers,
          currenTicketProps,
          curNumber,
          td.length
        );
        td.push(currentSquare);
      }
      tr.push(<tr>{td}</tr>);
    }
    return tr;
  }
  editTicketRuleTop3(ticketNumber, currentRule) {
    if (!this.props?.ticketWaiting) {
      this.props.editTicketRule(ticketNumber, currentRule);
    }
  }
  getTop3GameSettingControlPanel(ticketNumber) {
    return (
      this.props.gameName === "top3" && (
        <Col>
          <Row>{this.getHeader()}</Row>
          <Row>
            <Top3GameSettingsControlPanel
              messagePopupWasOpened={this.props.messagePopupWasOpened}
              editTicketRule={(currentRule) =>
                this.editTicketRuleTop3(ticketNumber, currentRule)
              }
              gameSetting={this.props.gameSetting}
              top3RulesList={this.props.top3RulesList}
              ticketWaiting={this.props?.ticketWaiting}
              idTicket={this.props.keyIndex}
              waitResult={this.props.waitResult}
            />
          </Row>
        </Col>
      )
    );
  }

  getFirstKenoGame() {
    console.log(this.props.waitResult);
    return (
      this.props.gameName === "keno2" && (
        <KenoFirstGame
          messagePopupWasOpened={this.props.messagePopupWasOpened}
          setFirstGameChoiseKeno={(key, ticketNumber, ticketIndex) => {
            this.props.setFirstGameChoise(key, ticketNumber, ticketIndex);
          }}
          rules={this.props.rules}
          ticketInfo={this.props.ticketInfo}
          ticketNumber={this.props.ticketInfo["key"]}
          keyIndex={this.props.keyIndex}
          waitResult={this.props.waitResult}
        />
      )
    );
  }

  setKenoThreeGameChoise = (ticketKey, col) => {
    this.props.setThreeGameChoise(ticketKey, col);
  };

  isVerticalAxis() {
    return (
      [
        "6x45",
        "7x49",
        "1224",
        "zabava",
        "top3",
        "keno2",
        "5x36plus",
        "5x2",
      ].includes(this.props.gameName) || detectDevice() == "mobile"
    );
  }

  isDrawRange() {
    return [
      "4x20",
      "oxota",
      "rapido-drive",
      "rapido-ultra",
      "duel",
      "dvazhdydva",
      "1224",
      "zabava",
      "rapido",
      "rapido2",
      "6x45",
      "7x49",
    ].includes(this.props.gameName);
  }

  getEndindingWithAccordingCount(count) {
    if (count >= 20) {
      count = count % 10;
    }
    if (1 < count && count < 5) {
      return "числа";
    }
    if (count >= 5 || count == 0) {
      return "чисел";
    }
    return "число";
  }

  getRangeText(ticketType) {
    const ticketMapper = {
      mainTicket: "firstTicket",
      subticket: "secondTicket",
    };

    const selectedNumbers =
      ticketType === "mainTicket"
        ? this.props.selectedNumbersFirstPlayingField
        : this.props.selectedNumbersSecondPlayingField;
    const restrictionMinimalQty =
      this.props.rules[ticketMapper[ticketType]]?.restrictions.min;
    if (["1224", "zabava"].includes(this.props.gameName)) {
      if (selectedNumbers.size == 12) {
        return this.props?.ticketWaiting
          ? "Выбрано: "
          : "Выбрано максимум чисел";
      }
    }
    if (["6x45", "7x49"].includes(this.props.gameName)) {
      if (selectedNumbers.size == 0) {
        return this.props?.ticketWaiting
          ? "Выбрано: "
          : "Выберите минимум " + restrictionMinimalQty + " чисел";
      }
      if (selectedNumbers.size > restrictionMinimalQty) {
        return this.props?.ticketWaiting
          ? "Выбрано: "
          : "Выбрано " + selectedNumbers.size + " чисел";
      }
    }
    if (
      [
        "4x20",
        "oxota",
        "rapido-drive",
        "rapido-ultra",
        "duel",
        "dvazhdydva",
        "rapido",
        "rapido2",
      ].includes(this.props.gameName)
    ) {
      return this.props?.ticketWaiting
        ? "Выбрано: "
        : "Выбрано " +
            selectedNumbers.size +
            " " +
            this.getEndindingWithAccordingCount(selectedNumbers.size);
    }
    return this.props?.ticketWaiting
      ? "Выбрано: "
      : selectedNumbers.size + " из " + restrictionMinimalQty + " чисел";
  }

  doNotWriteRules() {
    // на данном этапе ф-и isDrawRange и doNotWriteRules выглядят одинаково, но на самом деле в них может оказаться разное подмножество игр

    return ["1224", "zabava", "6x45", "7x49"].includes(this.props.gameName);
  }

  getTopMarginForSecondRowticket() {
    return ["duel", "dvazhdydva"].includes(this.props.gameName) ? "-3em" : "";
  }
  getHeaderPadding() {
    if (isSmallScreen()) {
      if (["6x45"].includes(this.props.gameName)) {
        return "0px";
      }
      if (["keno2"].includes(this.props.gameName)) {
        return "13px";
      }
    }
  }
  getLeftMarginsHeaderText() {
    if (detectDevice() == "mobile") {
      if (["keno2"].includes(this.props.gameName)) {
        return "0px";
      }
      if (["1224", "zabava"].includes(this.props.gameName)) {
        return "0px";
      }
      return "0px";
    }
  }
  getHeader() {
    const ticketText = this.props?.ticketWaiting
      ? "Ваш билет"
      : "Билет " + (this.props.keyIndex + 1);
    return this.props.gameName !== "top3" ? (
      <LottoTicketsControllerTextBoxBigTitleFirstField
        specialMargins={this.getLeftMarginsHeaderText()}
        headerPadding={this.getHeaderPadding()}
        thisIsTop3TicketNumber={this.props.gameName == "top3"}
        haveNoTicketRules={this.props.rules.ticketRules == null}
      >
        {ticketText}
      </LottoTicketsControllerTextBoxBigTitleFirstField>
    ) : (
      <LottoTicketsControllerTextBoxBigTitle
        isKeno={this.props.gameName == "keno2"}
        isTop3Game={this.props.gameName == "top3"}
        style={{ marginTop: "0px" }}
      >
        {ticketText}
      </LottoTicketsControllerTextBoxBigTitle>
    );
  }

  getArrows(currentKey) {
    return (
      !isTouch() && (
        <Col style={{ paddingLeft: "25px" }}>
          <ArrowButtonStyles
            tabIndex={this.props.messagePopupWasOpened ? -1 : 0}
            id={currentKey}
            size="s"
            onClick={(e) => this.props.leftArrowPress(e, currentKey)}
            square={true}
          >
            <IconChevronLeft size="xs"></IconChevronLeft>
          </ArrowButtonStyles>
          <ArrowButtonStyles
            tabIndex={this.props.messagePopupWasOpened ? -1 : 0}
            id={currentKey}
            size="s"
            onClick={(e) => this.props.rightArrowPress(e, currentKey)}
            square={true}
          >
            {" "}
            <IconChevronRight size="xs"></IconChevronRight>
          </ArrowButtonStyles>
        </Col>
      )
    );
  }

  getSpecialPaddingHeader() {
    if (isFullHD()) {
      return !["keno2"].includes(this.props.gameName) ? "7px" : "0px";
    }
    if (isSmallScreen()) {
      if (["keno2"].includes(this.props.gameName)) {
        return "7px";
      }
    }
  }

  getSpecPaddingTopHeader() {
    if (isFullHD()) {
      if (["keno2"].includes(this.props.gameName)) {
        return "15px";
      }
    }
  }

  getHeaderWithArrows(currentKey) {
    return (
      <LottoTicketControllerRowHeader
        specPaddingTop={this.getSpecPaddingTopHeader()}
        id="target"
        specPaddingLeft={this.getSpecialPaddingHeader()}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Col style={{ maxHeight: "50px" }}>{this.getHeader()}</Col>
        {this.getArrows(currentKey)}
      </LottoTicketControllerRowHeader>
    );
  }

  doNotWriteRulesOnMobile() {
    return (
      detectDevice() == "mobile" &&
      ["rapido", "rapido2", "rapido-drive", "rapido-ultra"].includes(
        this.props.gameName
      )
    );
  }

  rowStylesTicket() {
    if (isFullHD()) {
      if (
        ["duel", "4x20", "oxota", "dvazhdydva"].includes(this.props.gameName)
      ) {
        return { display: "flex", flexWrap: "nowrap", paddingTop: "20px" };
      }
      if (["1224", "zabava"].includes(this.props.gameName)) {
        return { display: "flex", flexWrap: "nowrap", paddingTop: "10px" };
      }
      if (
        ["rapido", "rapido2", "rapido-drive", "rapido-ultra"].includes(
          this.props.gameName
        )
      ) {
        return { display: "flex", flexWrap: "nowrap", paddingTop: "10px" };
      }
      if (["5x36plus", "5x2"].includes(this.props.gameName)) {
        return { display: "flex", paddingTop: "12px" };
      }
      if (["1224", "zabava"].includes(this.props.gameName)) {
        return { display: "flex", flexWrap: "nowrap", paddingTop: "9px" };
      }
    }
    if (isSmallScreen()) {
      if (
        ["duel", "4x20", "oxota", "1224", "zabava", "dvazhdydva"].includes(
          this.props.gameName
        )
      ) {
        return { display: "flex", flexWrap: "nowrap" };
      }
      if (["1224", "zabava"].includes(this.props.gameName)) {
        return { display: "flex", flexWrap: "nowrap" };
      }
      if (
        ["rapido", "rapido2", "rapido-drive", "rapido-ultra"].includes(
          this.props.gameName
        )
      ) {
        return { display: "flex" };
      }
      if (["5x36plus", "5x2"].includes(this.props.gameName)) {
        return { display: "flex", paddingTop: "0px" };
      }
    }
    if (detectDevice() == "mobile") {
      if (
        [
          "rapido",
          "rapido2",
          "rapido-drive",
          "rapido-ultra",
          "5x36plus",
          "5x2",
        ].includes(this.props.gameName)
      ) {
        return { display: "flex", flexWrap: "nowrap", flexDirection: "column" };
      }
      if (
        ["duel", "dvazhdydva", "4x20", "oxota"].includes(this.props.gameName)
      ) {
        return { paddingTop: "10px" };
      }
    }
  }

  getSecondTicketRowStyles() {
    if (isFullHD()) {
      if (["top3"].includes(this.props.gameName)) {
        return { paddingLeft: "48px" };
      }
    }
    if (
      [
        "duel",
        "dvazhdydva",
        "4x20",
        "oxota",
        "rapido-drive",
        "rapido-ultra",
        "rapido",
        "rapido2",
        "5x36plus",
        "5x2",
      ].includes(this.props.gameName)
    ) {
      if (isFullHD()) {
        return { paddingTop: "0px" };
      }
    }
    if (["6x45"].includes(this.props.gameName)) {
      if (isFullHD()) {
        return { paddingTop: "4px" };
      }
    }
    if (["top3"].includes(this.props.gameName)) {
      if (isFullHD()) {
        return { paddingTop: "4px" };
      }
    }
    if (["1224", "zabava", "6x45", "7x49"].includes(this.props.gameName)) {
      if (detectDevice() == "mobile") {
        return { paddingBottom: "20px", marginLeft: "0px", paddingLeft: "0px" };
      }
    }
    if (this.props.gameName == "keno2") {
      if (isSmallScreen()) {
        return { paddingBottom: "30px" };
      }
      if (isFullHD()) {
        return { paddingBottom: "19px" };
      }
      if (detectDevice() == "mobile") {
        return { marginTop: "6px", marginLeft: "-1px" };
      }
    }
  }
  getSpecialPaddingTopSubtitle() {
    if (isSmallScreen()) {
      if (
        ["duel", "4x20", "oxota", "dvazhdydva"].includes(this.props.gameName)
      ) {
        return "0px";
      }
      if (["5x36plus", "5x2"].includes(this.props.gameName)) {
        return "0px";
      }
      if (
        ["rapido", "rapido2", "rapido-drive", "rapido-ultra"].includes(
          this.props.gameName
        )
      ) {
        return "0px";
      }
    }
  }
  getSpecialMarginSubtitle(secondTicketFlag) {
    if (isFullHD()) {
      if (
        ["duel", "4x20", "oxota", "dvazhdydva"].includes(this.props.gameName)
      ) {
        return "20px";
      }
      if (
        ["rapido", "rapido2", "rapido-drive", "rapido-ultra"].includes(
          this.props.gameName
        )
      ) {
        return secondTicketFlag ? "0px" : "0px";
      }
    }
    if (["6x45", "1224", "7x49", "zabava"].includes(this.props.gameName)) {
      if (isFullHD()) {
        return "0px";
      }
    }
    if (isSmallScreen()) {
      if (
        ["4x20", "oxota", "duel", "dvazhdydva"].includes(this.props.gameName)
      ) {
        return secondTicketFlag ? "0px" : "0px";
      }
      if (["5x36plus", "5x2"].includes(this.props.gameName)) {
        return secondTicketFlag ? "0px" : "0px";
      }
      if (
        ["rapido", "rapido2", "rapido-drive", "rapido-ultra"].includes(
          this.props.gameName
        )
      ) {
        return secondTicketFlag ? "0px" : "0px";
      }
      if (["1224", "7x49", "zabava"].includes(this.props.gameName)) {
        return "0px";
      }
      if (["6x45"].includes(this.props.gameName)) {
        return "5px";
      }
    }
  }
  getSpecialPaddingLeftSubtitle() {
    if (detectDevice() == "mobile") {
      if (["keno2"].includes(this.props.gameName)) {
        return "11px";
      }
    }
  }
  getMainHeightOfTicket() {
    if (isSmallScreen()) {
      if (["6x45"].includes(this.props.gameName)) {
        return "14.5em";
      }
      if (["7x49"].includes(this.props.gameName)) {
        return "17.0em";
      }
      if (["4x20", "oxota"].includes(this.props.gameName)) {
        return "16em";
      }
      if (["1224", "zabava"].includes(this.props.gameName)) {
        return "10.75em";
      }
      if (["duel", "dvazhdydva"].includes(this.props.gameName)) {
        return "17.25em";
      }
      if (["top3"].includes(this.props.gameName)) {
        return "17em";
      }
      if (
        ["rapido", "rapido2", "rapido-drive", "rapido-ultra"].includes(
          this.props.gameName
        )
      ) {
        return "17em";
      }
      if (["5x36plus", "5x2"].includes(this.props.gameName)) {
        return "16.2em";
      }
    }
    if (isFullHD()) {
      if (
        ["rapido", "rapido2", "rapido-drive", "rapido-ultra"].includes(
          this.props.gameName
        )
      ) {
        return "18.2em";
      }
      if (["5x36plus", "5x2"].includes(this.props.gameName)) {
        return "22em";
      }
      if (["duel", "dvazhdydva"].includes(this.props.gameName)) {
        return "22em";
      }
      if (["4x20", "oxota"].includes(this.props.gameName)) {
        return "22em";
      }
      if (["1224", "zabava"].includes(this.props.gameName)) {
        return "14em";
      }
    }
  }
  getSpecPaddingTicketRule() {
    if (isFullHD()) {
      if (
        ["duel", "dvazhdydva", "4x20", "oxota"].includes(this.props.gameName)
      ) {
        return "7px";
      }
      return "7px";
    }
    if (isSmallScreen()) {
      if (
        ["duel", "dvazhdydva", "4x20", "oxota"].includes(this.props.gameName)
      ) {
        return "0px";
      }
    }
  }
  specialPaddingBigTitle(secondSquareflag) {
    if (isFullHD()) {
      if (
        [
          "duel",
          "dvazhdydva",
          "5x2",
          "4x20",
          "oxota",
          "5x36plus",
          "rapido",
          "rapido2",
          "rapido-drive",
          "rapido-ultra",
        ].includes(this.props.gameName)
      ) {
        return "0px";
      }
    }
    if (isSmallScreen()) {
      if (
        ["duel", "4x20", "oxota", "dvazhdydva"].includes(this.props.gameName)
      ) {
        return "0px";
      }
      if (["5x36plus", "5x2"].includes(this.props.gameName)) {
        return secondSquareflag ? "0px" : "0px";
      }
      if (
        ["rapido", "rapido2", "rapido-drive", "rapido-ultra"].includes(
          this.props.gameName
        )
      ) {
        return secondSquareflag ? "0px" : "0px";
      }
    }
  }
  rowSquareStyles(secondTicketFlag) {
    if (isFullHD()) {
      if (["5x36plus", "5x2", "7x49", "6x45"].includes(this.props.gameName)) {
        return { paddingLeft: "8px" };
      }
      if (["4x20", "oxota"].includes(this.props.gameName)) {
        return {
          display: "flex",
          alignItems: "baseline",
          paddingTop: !secondTicketFlag ? "0px" : "0px",
          paddingLeft: "7px",
        };
      }
      if (["duel", "dvazhdydva"].includes(this.props.gameName)) {
        return {
          display: "flex",
          alignItems: "baseline",
          paddingTop: !secondTicketFlag ? "0px" : "0px",
          paddingLeft: "7px",
        };
      }
      return { paddingLeft: "7px" };
    }
    if (isSmallScreen()) {
      if (
        ["duel", "4x20", "oxota", "dvazhdydva"].includes(this.props.gameName)
      ) {
        return {
          display: "flex",
          alignItems: "baseline",
          flexDirection: "column",
        };
      }
    }
    return { display: "flex", alignItems: "baseline" };
  }

  rowSquareColStyles(secondTicketFlag) {
    if (isSmallScreen()) {
      if (
        ["rapido", "rapido2", "rapido-drive", "rapido-ultra"].includes(
          this.props.gameName
        )
      ) {
        return {
          display: "flex",
          alignItems: "baseline",
          flexDirection: "column",
        };
      }
    }
  }

  getPaddingLeftSecondTicket() {
    if (isSmallScreen()) {
      if (["4x20", "oxota"].includes(this.props.gameName)) {
        return "1.5em";
      }
      if (["duel", "dvazhdydva"].includes(this.props.gameName)) {
        return "1.5em";
      }
      if (
        [
          "5x36plus",
          "5x2",
          "rapido",
          "rapido2",
          "rapido-drive",
          "rapido-ultra",
        ].includes(this.props.gameName)
      ) {
        return "0px";
      }
    }
    if (isFullHD()) {
      if (["4x20", "oxota"].includes(this.props.gameName)) {
        return "2em";
      }
      if (
        ["rapido", "rapido2", "rapido-drive", "rapido-ultra"].includes(
          this.props.gameName
        )
      ) {
        return "1.5em";
      }
      if (["duel", "dvazhdydva"].includes(this.props.gameName)) {
        return "2em";
      }
      if (["5x36plus", "5x2"].includes(this.props.gameName)) {
        return "0px";
      }
    }
    if (detectDevice() == "mobile") {
      if (["duel", "dvazhdydva"].includes(this.props.gameName)) {
        return "37px";
      }
      if (["4x20", "oxota"].includes(this.props.gameName)) {
        return "45px";
      }
      if (
        ["rapido", "rapido2", "rapido-drive", "rapido-ultra", "5x36plus", "5x2"]
      ) {
        return "0px";
      }
    }
    // if([ 'rapido', 'rapido2'].includes(this.props.gameName)){
    //     return '0em'
    // }
    return "0.3em";
  }
  getSpecMarginTopMobile() {
    if (detectDevice() == "mobile") {
      if (
        ["4x20", "oxota", "duel", "dvazhdydva"].includes(this.props.gameName)
      ) {
        return "0px";
      }
    }
  }
  thisGameDisplayContentsOnMobile() {
    if (detectDevice() == "mobile") {
      if (
        [
          "rapido",
          "6x45",
          "7x49",
          "rapido2",
          "rapido-drive",
          "rapido-ultra",
          "4x20",
          "oxota",
          "duel",
          "dvazhdydva",
          "5x36plus",
          "5x2",
        ].includes(this.props.gameName)
      ) {
        return true;
      }
    }
    return false;
  }
  getTextColMobileStyles() {
    if (detectDevice() == "mobile") {
      if (
        ["4x20", "oxota", "duel", "dvazhdydva"].includes(this.props.gameName)
      ) {
        return { paddingLeft: "0px" };
      }
      if (
        [
          "7x49",
          "6x45",
          "rapido",
          "rapido2",
          "rapido-drive",
          "rapido-ultra",
          "5x36plus",
          "5x2",
          "1224",
          "zabava",
        ].includes(this.props.gameName)
      ) {
        return { paddingLeft: "0px" };
      }
    }
  }
  getPaddingTopSecondTicket() {
    if (detectDevice() == "mobile") {
      if (
        ["4x20", "oxota", "duel", "dvazhdydva"].includes(this.props.gameName)
      ) {
        return "0x";
      }
    }
    if (isFullHD()) {
      if (
        ["4x20", "oxota", "duel", "dvazhdydva"].includes(this.props.gameName)
      ) {
        return "0px";
      }
      if (
        ["rapido", "rapido2", "rapido-drive", "rapido-ultra"].includes(
          this.props.gameName
        )
      ) {
        return "0px";
      }
    }
  }
  getMobileStylesFirstCol() {
    if (detectDevice() == "mobile") {
      if (
        ["4x20", "oxota", "duel", "dvazhdydva"].includes(this.props.gameName)
      ) {
        return { paddingLeft: "0px", height: "42px" };
      }
      if (
        [
          "rapido",
          "rapido2",
          "rapido-drive",
          "rapido-ultra",
          "5x36plus",
          "5x2",
        ].includes(this.props.gameName)
      ) {
        return { paddingLeft: "0px" };
      }
    }
  }
  getTextBoxFirstSquarePadding() {
    if (detectDevice() == "mobile") {
      if (
        [
          "rapido",
          "rapido2",
          "rapido-drive",
          "rapido-ultra",
          "5x36plus",
          "5x2",
        ].includes(this.props.gameName)
      ) {
        return { paddingLeft: "0px" };
      }
    }
  }
  isZeroPaddingSecondSquare() {
    if (isFullHD()) {
      return ["5x36plus", "5x2"].includes(this.props.gameName);
    }
    if (isSmallScreen()) {
      return [
        "rapido",
        "rapido2",
        "rapido-drive",
        "rapido-ultra",
        "5x36plus",
        "5x2",
        "keno2",
        "top3",
      ].includes(this.props.gameName);
    }
    if (detectDevice() == "mobile") {
      return [
        "rapido",
        "rapido2",
        "rapido-drive",
        "rapido-ultra",
        "5x36plus",
        "5x2",
      ].includes(this.props.gameName);
    }
    return false;
  }

  getSpecialMarginSquareGameRule(secondTicketFlag) {
    if (isFullHD()) {
      if (
        ["duel", "4x20", "oxota", "dvazhdydva"].includes(this.props.gameName)
      ) {
        return "20px";
      }
      if (["1224", "zabava", "7x49", "6x45"].includes(this.props.gameName)) {
        return "0px";
      }
      if (
        ["rapido", "rapido2", "rapido-drive", "rapido-ultra"].includes(
          this.props.gameName
        )
      ) {
        return secondTicketFlag ? "0px" : "20px";
      }
    }
    if (isSmallScreen()) {
      if (
        ["duel", "dvazhdydva", "4x20", "oxota"].includes(this.props.gameName)
      ) {
        return !secondTicketFlag ? "0px" : "0px";
      }
      if (["1224", "zabava", "7x49", "6x45"].includes(this.props.gameName)) {
        return "0px";
      }
      if (
        ["rapido", "rapido2", "rapido-drive", "rapido-ultra"].includes(
          this.props.gameName
        )
      ) {
        return secondTicketFlag ? "10px" : "10px";
      }
    }
  }

  getSpecialMarginsTopSubtitle() {
    if (isFullHD()) {
      if (
        ["rapido", "rapido2", "rapido-drive", "rapido-ultra"].includes(
          this.props.gameName
        )
      ) {
        return "2px";
      }
    }
    if (isSmallScreen()) {
      if (
        ["rapido", "rapido2", "rapido-drive", "rapido-ultra"].includes(
          this.props.gameName
        )
      ) {
        return "0px";
      }
    }
  }
  getPaddingLeftMainContentTicket() {
    if (detectDevice() == "mobile") {
      if (this.props.gameName == "top3") {
        return "0px";
      }
      if (this.props.gameName == "keno2") {
        return "15px";
      }
      return "20px";
    }
  }
  getPaddingLeftLottoTicketsCol() {
    if (detectDevice() == "mobile") {
      if (
        ["5x36plus", "5x2", "4x20", "oxota", "duel", "dvazhdydva"].includes(
          this.props.gameName
        )
      ) {
        return { paddingLeft: "0px" };
      }
    }
  }
  getPaddingBottomSecondTicketRow() {
    if (isSmallScreen()) {
      if (["5x36plus", "5x2"].includes(this.props.gameName)) {
        return "10px";
      }
    }
    if (isFullHD()) {
      if (["5x36plus", "5x2"].includes(this.props.gameName)) {
        return "9px";
      }
    }
    if (detectDevice() == "mobile") {
      if (
        [
          "rapido",
          "rapido2",
          "rapido-drive",
          "rapido-ultra",
          "5x36plus",
          "5x2",
        ].includes(this.props.gameName)
      ) {
        return "10px";
      }
    }
  }

  getTableStyles() {
    if (!isFullHD()) {
      return { borderSpacing: "0px" };
    }
    if (isFullHD()) {
      if (["6x45", "7x49", "5x36plus", "5x2"].includes(this.props.gameName)) {
        return { paddingLeft: "1px", paddingRight: "1px" };
      }
    }
  }

  render() {
    const kenoHeight = {
      sberPortal: { maxHeight: "17em" },
      sberBox: { maxHeight: "22em" },
    };
    return (
      <>
        <LottoTicketsControllerCard
          tabIndex={-1}
          paddingLeft={this.getPaddingLeftMainContentTicket()}
          isCentered={
            detectDevice() == "mobile" &&
            ["6x45", "7x49"].includes(this.props.gameName)
          }
          focused={false}
          ticketsMoreThanOne={this.props.ticketQty > 1}
          key={this.props.ticketInfo.key}
          isHorisontal={
            this.isVerticalAxis() ||
            ["duel", "dvazhdydva"].includes(this.props.gameName)
          }
        >
          <LottoTicketsControllerRow
            id="lalal1"
            settingHeight={this.getMainHeightOfTicket()}
            isTop3Game={this.props.gameName == "top3"}
            style={
              this.props.gameName == "keno2" && detectDevice() != "mobile"
                ? kenoHeight[detectDeviceSize()]
                : null
            }
          >
            {detectDevice() == "mobile" && this.props.gameName !== "top3" && (
              <Col style={this.getMobileStylesFirstCol()}>
                <LottoTicketsControllerTextBoxFirstTicket>
                  <LottoTicketControllerRowHeader
                    isKenoGame={this.props.gameName == "keno2"}
                  >
                    {this.props.gameName !== "top3" && this.getHeader()}
                  </LottoTicketControllerRowHeader>
                  {!this.doNotWriteRules() && (
                    <Row>
                      {" "}
                      <LottoTicketsControllerTextBoxSubTitle
                        specMarginTopMobile={this.getSpecMarginTopMobile()}
                      >
                        {this.props.rules.ticketRule}
                      </LottoTicketsControllerTextBoxSubTitle>
                    </Row>
                  )}
                </LottoTicketsControllerTextBoxFirstTicket>
              </Col>
            )}
            {detectDevice() != "mobile" &&
              this.props.gameName !== "top3" &&
              this.props.gameName !== "keno2" && (
                <Col
                  style={
                    detectDevice() != "mobile" && {
                      maxHeight: [
                        "7x49",
                        "4x20",
                        "oxota",
                        "duel",
                        "dvazhdydva",
                        "6x45",
                      ].includes(this.props.gameName)
                        ? "75px"
                        : "50px",
                    }
                  }
                >
                  <Row style={{ display: "grid" }}>
                    {/* <LottoTicketsControllerTextBoxSubTitleRule> Билет 1</LottoTicketsControllerTextBoxBigTitleSquare> */}
                    {this.props.gameName !== "top3" &&
                      this.getHeaderWithArrows(this.props.keyIndex)}
                  </Row>
                  <Row id="t100">
                    {!this.doNotWriteRules() && (
                      <LottoTicketsControllerTextBoxSubTitle
                        specPadding={this.getSpecPaddingTicketRule()}
                        style={isSmallScreen() ? { height: "15px" } : {}}
                      >
                        {this.props.rules.ticketRule}
                      </LottoTicketsControllerTextBoxSubTitle>
                    )}
                  </Row>
                </Col>
              )}

            <LottoTicketsControllerStyledCarousel
              isMustBeDisplayContents={this.thisGameDisplayContentsOnMobile()}
              detectActive={true}
              scrollSnapType="mandatory"
              detectThreshold={0.8}
              index={this.state.carouselIndex}
              //paddingEnd='5%'
              onIndexChange={(index) => console.log(index)}
              axis={this.isVerticalAxis() ? "y" : "x"}
              key={this.props.keyIndex}
              scrollAlign="center"
              animatedScrollByIndex={true}
            >
              {detectDevice() != "mobile" &&
                this.props.gameName !== "top3" &&
                this.props.gameName == "keno2" && (
                  <Col
                    id="check"
                    style={
                      detectDevice() != "mobile" && {
                        maxHeight: [
                          "7x49",
                          "4x20",
                          "oxota",
                          "duel",
                          "dvazhdydva",
                          "6x45",
                        ].includes(this.props.gameName)
                          ? "75px"
                          : "50px",
                      }
                    }
                  >
                    <Row style={{ display: "grid" }}>
                      {this.props.gameName !== "top3" &&
                        this.getHeaderWithArrows(this.props.keyIndex)}
                    </Row>
                    <Row>
                      {!this.doNotWriteRules() && (
                        <LottoTicketsControllerTextBoxSubTitle
                          style={isSmallScreen() ? { height: "15px" } : {}}
                        >
                          {this.props.rules.ticketRule}
                        </LottoTicketsControllerTextBoxSubTitle>
                      )}
                    </Row>
                  </Col>
                )}
              <LottoTicketControllerMainRow
                tabIndex={-1}
                style={this.rowStylesTicket()}
              >
                {this.props.gameName == "keno2" && (
                  <StyledCarouselItem
                    onFocus={(e) => {
                      this.setState({ carouselIndex: 0 });
                    }}
                    id={0}
                    scrollSnapAlign="center"
                    isKeno={false}
                    style={{ paddingLeft: "0px", width: "100%" }}
                    isTop3Game={this.props.gameName == "top3"}
                    key={0}
                  >
                    <LottoTicketsControllerStyledCarouselItem
                      isKeno={true}
                      style={
                        detectDevice() == "mobile" ? { paddingLeft: "0px" } : {}
                      }
                      key="0"
                    >
                      {this.getFirstKenoGame()}
                    </LottoTicketsControllerStyledCarouselItem>
                  </StyledCarouselItem>
                )}
                <StyledCarouselItem
                  onFocus={(e) => {
                    this.setState({ carouselIndex: 1 });
                  }}
                  id={1}
                  scrollSnapAlign="center"
                  isKeno={["keno2"].includes(this.props.gameName)}
                  style={{ paddingLeft: "0px" }}
                  isTop3Game={this.props.gameName == "top3"}
                  key={1}
                >
                  {/* {this.props.gameName =='keno2' && <LottoTicketsControllerStyledCarouselItem isKeno={true} style={detectDevice()=='mobile' ? {paddingLeft: '0px'}: {}} key='0'>{this.getFirstKenoGame()}</LottoTicketsControllerStyledCarouselItem>} */}
                  {
                    <TextBox style={this.getTextBoxFirstSquarePadding()}>
                      {this.props.gameName !== "top3" && this.isDrawRange() ? (
                        isFullHD() ? (
                          <Col>
                            <Row style={this.rowSquareStyles(false)}>
                              {this.props.rules?.secondTicket && (
                                <LottoTicketsControllerTextBoxBigTitleSquare
                                  specPadding={this.specialPaddingBigTitle()}
                                >
                                  {this.props.rules?.secondTicket
                                    ? [
                                        "rapido",
                                        "rapido2",
                                        "rapido-drive",
                                        "rapido-ultra",
                                      ].includes(this.props.gameName)
                                      ? "ЧАСТЬ ПОЛЯ 1 "
                                      : "ПОЛЕ 1"
                                    : ""}
                                </LottoTicketsControllerTextBoxBigTitleSquare>
                              )}

                              {
                                <LottoTicketsControllerTextBoxSubTitleRule
                                  id="target"
                                  specialPaddingLeft={this.getSpecialPaddingLeftSubtitle()}
                                  specialMargin={this.getSpecialMarginSquareGameRule(
                                    false
                                  )}
                                  specialPaddingTop={this.getSpecialPaddingTopSubtitle()}
                                >
                                  {" "}
                                  {this.getRangeText("mainTicket")}
                                </LottoTicketsControllerTextBoxSubTitleRule>
                              }
                            </Row>
                            {this.props.rules?.firstTicket?.firstTicketRule && (
                              <Row>
                                {
                                  <LottoTicketsControllerTextBoxSubTitleRule
                                    specialPaddingTop={this.getSpecialPaddingTopSubtitle()}
                                    specialMargin={this.getSpecialMarginSubtitle(
                                      false
                                    )}
                                    specialMarginsTop={this.getSpecialMarginsTopSubtitle()}
                                  >
                                    {this.props.ticketWaiting
                                      ? "Выбрано: "
                                      : this.props.rules?.firstTicket
                                          ?.firstTicketRule}
                                  </LottoTicketsControllerTextBoxSubTitleRule>
                                }
                              </Row>
                            )}
                          </Col>
                        ) : (
                          <Col
                            className="kakkakak"
                            style={this.getTextColMobileStyles()}
                          >
                            {this.props.rules?.secondTicket && (
                              <LottoTicketsControllerTextBoxBigTitleSquare>
                                {this.props.rules?.secondTicket &&
                                [
                                  "rapido",
                                  "rapido2",
                                  "rapido-drive",
                                  "rapido-ultra",
                                ].includes(this.props.gameName)
                                  ? "ЧАСТЬ ПОЛЯ 1"
                                  : "ПОЛЕ 1"}
                              </LottoTicketsControllerTextBoxBigTitleSquare>
                            )}
                            {!this.doNotWriteRulesOnMobile() ? (
                              <LottoTicketsControllerTextBoxSubTitleRule>
                                {!this.props.ticketWaiting &&
                                  this.props.rules?.firstTicket
                                    ?.firstTicketRule}
                              </LottoTicketsControllerTextBoxSubTitleRule>
                            ) : null}
                            {
                              <LottoTicketsControllerTextBoxSubTitleRule
                                className="kakkakak23"
                                addPaddingLeft={[
                                  "6x45",
                                  "7x49",
                                  "1224",
                                ].includes(this.props.gameName)}
                                specialMargin={this.getSpecialMarginSubtitle(
                                  false
                                )}
                                specialMargins={false}
                              >
                                {" "}
                                {this.props.ticketWaiting
                                  ? "Выбрано: "
                                  : this.getRangeText("mainTicket")}
                              </LottoTicketsControllerTextBoxSubTitleRule>
                            }
                          </Col>
                        )
                      ) : [
                          "5x36plus",
                          "5x2",
                          "rapido",
                          "rapido2",
                          "rapido-drive",
                          "rapido-ultra",
                        ].includes(this.props.gameName) && isFullHD() ? (
                        <Row style={this.rowSquareStyles(false)}>
                          {this.props.gameName !== "top3" &&
                            this.props.rules?.secondTicket && (
                              <Row>
                                <LottoTicketsControllerTextBoxBigTitleSquare
                                  specPadding={this.specialPaddingBigTitle()}
                                >
                                  {this.props.rules?.secondTicket &&
                                  [
                                    "rapido",
                                    "rapido2",
                                    "rapido-drive",
                                    "rapido-ultra",
                                  ].includes(this.props.gameName)
                                    ? "ЧАСТЬ ПОЛЯ 1"
                                    : "ПОЛЕ 1"}
                                </LottoTicketsControllerTextBoxBigTitleSquare>
                              </Row>
                            )}
                          {this.props.gameName !== "top3" && (
                            <Row
                              style={
                                ![
                                  "rapido",
                                  "rapido2",
                                  "rapido-drive",
                                  "rapido-ultra",
                                  "7x49",
                                  "5x36plus",
                                  "5x2",
                                ].includes(this.props.gameName)
                                  ? isFullHD()
                                    ? { marginBottom: "30px" }
                                    : { marginBottom: "10px" }
                                  : {}
                              }
                            >
                              {
                                <LottoTicketsControllerTextBoxSubTitleRuleRow
                                  isRowDimention={true}
                                >
                                  {this.props.ticketWaiting
                                    ? "Выбрано: "
                                    : this.props.rules?.firstTicket
                                        ?.firstTicketRule}
                                </LottoTicketsControllerTextBoxSubTitleRuleRow>
                              }
                            </Row>
                          )}
                        </Row>
                      ) : (
                        <Col>
                          {this.props.gameName !== "top3" &&
                            this.props.rules?.secondTicket && (
                              <Row>
                                <LottoTicketsControllerTextBoxBigTitleSquare
                                  specPadding={this.specialPaddingBigTitle()}
                                >
                                  {this.props.rules?.secondTicket &&
                                  [
                                    "rapido",
                                    "rapido2",
                                    "rapido-drive",
                                    "rapido-ultra",
                                  ].includes(this.props.gameName)
                                    ? "ЧАСТЬ ПОЛЯ 1"
                                    : "ПОЛЕ 1"}
                                </LottoTicketsControllerTextBoxBigTitleSquare>
                              </Row>
                            )}
                          {this.props.gameName !== "top3" && (
                            <Row
                              style={
                                ![
                                  "rapido",
                                  "rapido2",
                                  "rapido-drive",
                                  "rapido-ultra",
                                  "7x49",
                                  "5x36plus",
                                  "5x2",
                                ].includes(this.props.gameName)
                                  ? isFullHD()
                                    ? { marginBottom: "30px" }
                                    : { marginBottom: "10px" }
                                  : {}
                              }
                            >
                              {
                                <LottoTicketsControllerTextBoxSubTitleRule
                                  specialPaddingTop={this.getSpecialPaddingTopSubtitle()}
                                  specialMargin={this.getSpecialMarginSubtitle(
                                    false
                                  )}
                                >
                                  {!this.props.ticketWaiting &&
                                    this.props.rules?.firstTicket
                                      ?.firstTicketRule}
                                </LottoTicketsControllerTextBoxSubTitleRule>
                              }
                            </Row>
                          )}
                        </Col>
                      )}
                    </TextBox>
                  }
                  {this.props.gameName == "keno2" && (
                    <TextBox>
                      <LottoTicketsControllerTextBoxSubTitleRuleKeno
                        style={{ color: "white" }}
                      >
                        {this.props.ticketWaiting
                          ? "Выбрано: "
                          : this.props.rules["secondGame"]["firstTicket"]
                              .firstTicketRule}
                      </LottoTicketsControllerTextBoxSubTitleRuleKeno>
                    </TextBox>
                  )}

                  <SecondTicketRow
                    id="lalal"
                    paddingBottom={this.getPaddingBottomSecondTicketRow()}
                    style={this.getSecondTicketRowStyles()}
                  >
                    {this.props.gameName == "top3" && (
                      <LottoTicketsColTop3
                        key={this.props.rules["secondTicket"] && 1}
                      >
                        {this.getTop3GameSettingControlPanel(
                          this.props.ticketInfo["key"]
                        )}
                      </LottoTicketsColTop3>
                    )}
                    <LottoTicketsCol
                      style={this.getPaddingLeftLottoTicketsCol()}
                      isTop3Game={this.props.gameName == "top3"}
                    >
                      <table
                        key={this.props.rules["secondTicket"] && 1}
                        style={this.getTableStyles()}
                      >
                        <tbody>
                          {this.ticketConstructor(
                            this.props.keyIndex,
                            this.props.ticketInfo["key"],
                            "mainTicket"
                          )}
                        </tbody>
                      </table>
                    </LottoTicketsCol>
                    {this.props.gameName == "top3" &&
                      !isTouch() &&
                      this.getArrows(this.props.keyIndex)}
                  </SecondTicketRow>
                </StyledCarouselItem>
                <StyledCarouselItem
                  scrollSnapAlign="center"
                  onFocus={(e) => {
                    this.setState({ carouselIndex: 2 });
                  }}
                  id={2}
                  isZeroPadding={this.isZeroPaddingSecondSquare()}
                  key={2}
                >
                  {this.props.gameName != "keno2" ? (
                    (this.props.rules["secondTicket"] ||
                      this.props.rules["threeGame"]) && (
                      <LottoTicketsControllerCol
                        gameSquarePaddingLeft={[
                          "4x20",
                          "oxota",
                          "duel",
                          "dvazhdydva",
                        ].includes(this.props.gameName)}
                        specPaddingTop={this.getPaddingTopSecondTicket()}
                        getPaddingLeft={this.getPaddingLeftSecondTicket()}
                      >
                        <LottoTicketsControllerTextBoxSecondTicket>
                          {this.isDrawRange() ? (
                            isFullHD() ? (
                              [
                                "rapido",
                                "rapido2",
                                "rapido-drive",
                                "rapido-ultra",
                              ].includes(this.props.gameName) ? (
                                <Col style={this.rowSquareColStyles(true)}>
                                  <Row style={this.rowSquareStyles(false)}>
                                    <LottoTicketsControllerTextBoxBigTitleSquare
                                      specPadding={this.specialPaddingBigTitle(
                                        true
                                      )}
                                    >
                                      {[
                                        "rapido",
                                        "rapido2",
                                        "rapido-drive",
                                        "rapido-ultra",
                                      ].includes(this.props.gameName)
                                        ? "ЧАСТЬ ПОЛЯ 2"
                                        : "ПОЛЕ 2"}
                                    </LottoTicketsControllerTextBoxBigTitleSquare>
                                    {![
                                      "rapido",
                                      "rapido2",
                                      "rapido-drive",
                                      "rapido-ultra",
                                    ].includes(this.props.gameName) && (
                                      <LottoTicketsControllerTextBoxSubTitleRule
                                        specialPaddingLeft={this.getSpecialPaddingLeftSubtitle()}
                                        specialMargin={this.getSpecialMarginSquareGameRule(
                                          false
                                        )}
                                        specialPaddingTop={this.getSpecialPaddingTopSubtitle()}
                                      >
                                        {" "}
                                        {this.getRangeText("subticket")}
                                      </LottoTicketsControllerTextBoxSubTitleRule>
                                    )}
                                  </Row>
                                  {this.props.rules?.secondTicket
                                    ?.secondTicketRule && (
                                    <Row>
                                      <LottoTicketsControllerTextBoxSubTitleRule
                                        specialPaddingTop={this.getSpecialPaddingTopSubtitle()}
                                        specialMargin={this.getSpecialMarginSubtitle(
                                          true
                                        )}
                                      >
                                        {this.props.ticketWaiting
                                          ? "Выбрано:"
                                          : this.props.rules?.secondTicket
                                              ?.secondTicketRule}
                                      </LottoTicketsControllerTextBoxSubTitleRule>
                                    </Row>
                                  )}
                                </Col>
                              ) : (
                                <Row style={this.rowSquareStyles(true)}>
                                  <LottoTicketsControllerTextBoxBigTitleSquare
                                    specPadding={"0px"}
                                  >
                                    {[
                                      "rapido",
                                      "rapido2",
                                      "rapido-drive",
                                      "rapido-ultra",
                                    ].includes(this.props.gameName)
                                      ? "ЧАСТЬ ПОЛЯ 2"
                                      : "ПОЛЕ 2"}
                                  </LottoTicketsControllerTextBoxBigTitleSquare>
                                  {
                                    <LottoTicketsControllerTextBoxSubTitleRule
                                      specialPaddingLeft={this.getSpecialPaddingLeftSubtitle()}
                                      specialMargin={this.getSpecialMarginSubtitle(
                                        false
                                      )}
                                      specialPaddingTop={this.getSpecialPaddingTopSubtitle()}
                                    >
                                      {" "}
                                      {this.getRangeText("subticket")}
                                    </LottoTicketsControllerTextBoxSubTitleRule>
                                  }
                                  {this.props.rules?.secondTicket
                                    ?.secondTicketRule && (
                                    <Row>
                                      <LottoTicketsControllerTextBoxSubTitleRule
                                        id="zali2"
                                        specialMargin={this.getSpecialMarginSubtitle(
                                          true
                                        )}
                                      >
                                        {this.props.ticketWaiting
                                          ? "Выбрано:"
                                          : this.props.rules?.secondTicket
                                              ?.secondTicketRule}
                                      </LottoTicketsControllerTextBoxSubTitleRule>
                                    </Row>
                                  )}
                                </Row>
                              )
                            ) : (
                              <Col
                                className="test1111"
                                style={this.getTextColMobileStyles()}
                              >
                                <LottoTicketsControllerTextBoxBigTitleSquare>
                                  {[
                                    "rapido",
                                    "rapido2",
                                    "rapido-drive",
                                    "rapido-ultra",
                                  ].includes(this.props.gameName)
                                    ? "ЧАСТЬ ПОЛЯ 2"
                                    : "ПОЛЕ 2"}
                                </LottoTicketsControllerTextBoxBigTitleSquare>
                                {![
                                  "rapido",
                                  "rapido2",
                                  "rapido-drive",
                                  "rapido-ultra",
                                ].includes(this.props.gameName) ? (
                                  <LottoTicketsControllerTextBoxSubTitleRule
                                    secondSpecialPadding={
                                      [
                                        "4x20",
                                        "oxota",
                                        "duel",
                                        "dvazhdydva",
                                      ].includes(this.props.gameName) &&
                                      detectDevice() == "mobile"
                                    }
                                    specialMargin={this.getSpecialMarginSubtitle(
                                      false
                                    )}
                                    specialMargins={["1224"].includes(
                                      this.props.gameName
                                    )}
                                    className="testdddd"
                                  >
                                    {" "}
                                    {this.getRangeText("subticket")}
                                  </LottoTicketsControllerTextBoxSubTitleRule>
                                ) : null}
                                {
                                  <LottoTicketsControllerTextBoxSubTitleRule>
                                    {!this.props.ticketWaiting &&
                                      this.props.rules?.secondTicket
                                        ?.secondTicketRule}
                                  </LottoTicketsControllerTextBoxSubTitleRule>
                                }
                              </Col>
                            )
                          ) : ["5x36plus", "5x2"].includes(
                              this.props.gameName
                            ) && isFullHD() ? (
                            <Row style={this.rowSquareStyles()}>
                              <LottoTicketsControllerTextBoxBigTitleSquare
                                specPadding={this.specialPaddingBigTitle()}
                              >
                                {[
                                  "rapido",
                                  "rapido2",
                                  "rapido-drive",
                                  "rapido-ultra",
                                ].includes(this.props.gameName)
                                  ? "ЧАСТЬ ПОЛЯ 2"
                                  : "ПОЛЕ 2"}
                              </LottoTicketsControllerTextBoxBigTitleSquare>
                              <LottoTicketsControllerTextBoxSubTitleRuleRow>
                                {this.props.ticketWaiting
                                  ? "Выбрано:"
                                  : this.props.rules?.secondTicket
                                      ?.secondTicketRule}
                              </LottoTicketsControllerTextBoxSubTitleRuleRow>
                            </Row>
                          ) : (
                            <Col
                              style={
                                detectDevice() == "mobile"
                                  ? { paddingLeft: "0px" }
                                  : {}
                              }
                            >
                              <LottoTicketsControllerTextBoxBigTitleSquare
                                specPadding={this.specialPaddingBigTitle(true)}
                              >
                                {[
                                  "rapido",
                                  "rapido2",
                                  "rapido-drive",
                                  "rapido-ultra",
                                ].includes(this.props.gameName)
                                  ? "ЧАСТЬ ПОЛЯ 2"
                                  : "ПОЛЕ 2"}
                              </LottoTicketsControllerTextBoxBigTitleSquare>
                              {
                                <LottoTicketsControllerTextBoxSubTitleRule
                                  specialPaddingTop={this.getSpecialPaddingTopSubtitle(
                                    true
                                  )}
                                  specialMargin={this.getSpecialMarginSubtitle(
                                    true
                                  )}
                                >
                                  {this.props.ticketWaiting
                                    ? "Выбрано:"
                                    : this.props.rules?.secondTicket
                                        ?.secondTicketRule}
                                </LottoTicketsControllerTextBoxSubTitleRule>
                              }
                            </Col>
                          )}
                        </LottoTicketsControllerTextBoxSecondTicket>
                        {/* <LottoTicketsCol style={this.getPaddingLeftLottoTicketsCol()} isTop3Game={this.props.gameName=='top3'}> */}
                        <table key={2} style={this.getTableStyles()}>
                          <tbody>
                            {this.ticketConstructor(
                              this.props.keyIndex,
                              this.props.ticketInfo["key"],
                              "subticket"
                            )}
                          </tbody>
                        </table>
                        {/* </LottoTicketsCol> */}
                      </LottoTicketsControllerCol>
                    )
                  ) : (
                    <LottoKenoThirdGameRow>
                      <KenoThirdGame
                        waitResult={this.props.waitResult}
                        messagePopupWasOpened={this.props.messagePopupWasOpened}
                        rules={this.props.rules}
                        ticketInfo={this.props.ticketInfo}
                        setThreeGameChoise={this.props.setThreeGameChoise}
                        setThreeGameEmpty={this.props.setThreeGameEmpty}
                        showPayText={this.props.showPayText}
                        payMoney={this.props.payMoney}
                      />
                    </LottoKenoThirdGameRow>
                  )}
                </StyledCarouselItem>
              </LottoTicketControllerMainRow>
            </LottoTicketsControllerStyledCarousel>
          </LottoTicketsControllerRow>
          {/* <Button onClick={() => { this.props.setRandomNumbers(this.props.ticketInfo['key'], this.props.gameName) }}>Рандом</Button> */}
        </LottoTicketsControllerCard>
      </>
    );
  }
}

export default LottoTicketsController;
