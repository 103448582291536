// Generated by robots, do not change this manually!
export var darkSber = {
    ":root": {
        "--plasma-colors-white": "#FFFFFF",
        "--plasma-colors-whitePrimary": "#FFFFFF",
        "--plasma-colors-whiteSecondary": "rgba(255, 255, 255, 0.56)",
        "--plasma-colors-whiteTertiary": "rgba(255, 255, 255, 0.28)",
        "--plasma-colors-black": "#080808",
        "--plasma-colors-blackPrimary": "#080808",
        "--plasma-colors-blackSecondary": "rgba(8, 8, 8, 0.56)",
        "--plasma-colors-blackTertiary": "rgba(8, 8, 8, 0.28)",
        "--plasma-colors-dark01": "#171717",
        "--plasma-colors-dark02": "#232323",
        "--plasma-colors-dark03": "#363636",
        "--plasma-colors-transparent": "rgba(0, 0, 0, 0)",
        "--plasma-colors-buttonClear": "rgba(0, 0, 0, 0)",
        "--plasma-colors-buttonBlack": "#080808",
        "--plasma-colors-buttonBlackSecondary": "rgba(8, 8, 8, 0.12)",
        "--plasma-colors-buttonBlackTransparent": "rgba(8, 8, 8, 0.56)",
        "--plasma-colors-buttonWhite": "#FFFFFF",
        "--plasma-colors-buttonWhiteSecondary": "rgba(255, 255, 255, 0.12)",
        "--plasma-colors-text": "rgba(255, 255, 255, 0.96)",
        "--plasma-colors-primary": "rgba(255, 255, 255, 0.96)",
        "--plasma-colors-secondary": "rgba(255, 255, 255, 0.56)",
        "--plasma-colors-tertiary": "rgba(255, 255, 255, 0.28)",
        "--plasma-colors-paragraph": "rgba(255, 255, 255, 0.96)",
        "--plasma-colors-inverse": "#080808",
        "--plasma-colors-background": "#080808",
        "--plasma-colors-backgroundPrimary": "#171717",
        "--plasma-colors-backgroundSecondary": "#232323",
        "--plasma-colors-backgroundTertiary": "#363636",
        "--plasma-colors-success": "#24B23E",
        "--plasma-colors-warning": "#FA6D20",
        "--plasma-colors-critical": "#FF4D5F",
        "--plasma-colors-overlay": "rgba(0, 0, 0, 0.8)",
        "--plasma-colors-surfaceLiquid01": "rgba(255, 255, 255, 0.06)",
        "--plasma-colors-surfaceLiquid02": "rgba(255, 255, 255, 0.12)",
        "--plasma-colors-surfaceLiquid03": "rgba(255, 255, 255, 0.2)",
        "--plasma-colors-surfaceSolid01": "#171717",
        "--plasma-colors-surfaceSolid02": "#232323",
        "--plasma-colors-surfaceSolid03": "#363636",
        "--plasma-colors-surfaceCard": "rgba(255, 255, 255, 0.12)",
        "--plasma-colors-buttonPrimary": "#FFFFFF",
        "--plasma-colors-buttonSecondary": "rgba(255, 255, 255, 0.12)",
        "--plasma-colors-buttonSuccess": "#21A038",
        "--plasma-colors-buttonWarning": "#E35502",
        "--plasma-colors-buttonCritical": "#FF2E43",
        "--plasma-colors-buttonChecked": "#FFFFFF",
        "--plasma-colors-skeletonGradient": "linear-gradient( 90deg, rgba(255, 255, 255, 0.09) 0%, rgba(255, 255, 255, 0.08) 6.25%, rgba(255, 255, 255, 0.05) 12.5%, rgba(255, 255, 255, 0.01) 25%, rgba(255, 255, 255, 0.05) 37.5%, rgba(255, 255, 255, 0.08) 43.75%, rgba(255, 255, 255, 0.09) 50%, rgba(255, 255, 255, 0.08) 56.25%, rgba(255, 255, 255, 0.05) 62.5%, rgba(255, 255, 255, 0.01) 75%, rgba(255, 255, 255, 0.05) 87.5%, rgba(255, 255, 255, 0.08) 93.75%, rgba(255, 255, 255, 0.09) 100% )",
        "--plasma-colors-skeletonGradientLighter": "linear-gradient( 90deg, rgba(255, 255, 255, 0.36) 0%, rgba(255, 255, 255, 0.32) 6.25%, rgba(255, 255, 255, 0.20) 12.5%, rgba(255, 255, 255, 0.04) 25%, rgba(255, 255, 255, 0.20) 37.5%, rgba(255, 255, 255, 0.32) 43.75%, rgba(255, 255, 255, 0.36) 50%, rgba(255, 255, 255, 0.08) 56.25%, rgba(255, 255, 255, 0.20) 62.5%, rgba(255, 255, 255, 0.04) 75%, rgba(255, 255, 255, 0.20) 87.5%, rgba(255, 255, 255, 0.32) 93.75%, rgba(255, 255, 255, 0.36) 100% )",
        "--plasma-colors-speechBubbleSent": "rgba(0, 0, 0, 0.28)",
        "--plasma-colors-speechBubbleReceived": "rgba(255, 255, 255, 0.12)",
        "--plasma-colors-accent": "#24B23E",
        "--plasma-colors-buttonAccent": "#21A038",
        "--plasma-colors-buttonFocused": "#21A038",
        "--plasma-colors-gradient": "linear-gradient(336.84deg, rgba(20, 116, 70, 0.6) 0%, rgba(8, 8, 8, 0) 64.88%), radial-gradient(100% 100% at 75.89% 100%, rgba(0, 133, 255, 0.24) 0%, rgba(0, 71, 255, 0.03) 100%), linear-gradient(180deg, rgba(8, 8, 8, 0) 50%, rgba(7, 71, 33, 0.3) 100%), linear-gradient(270deg, #061621 0%, rgba(8, 8, 8, 0) 100%)",
        "--plasma-colors-gradientDevice": "linear-gradient(26.05deg, rgba(15, 153, 24, 0.28) 0%, rgba(8, 8, 8, 0) 72.24%), radial-gradient(100% 100% at 0% 100%, rgba(0, 170, 255, 0.24) 0%, rgba(8, 8, 8, 0) 99.69%), radial-gradient(74.68% 149.35% at 50% 149.35%, rgba(0, 102, 255, 0.6) 0%, rgba(8, 8, 8, 0) 99.69%)",
        "--plasma-colors-voicePhraseGradient": "linear-gradient(45deg, hsla(130, 75.63025210084032%, 46.666666666666664%, 1) 0%, hsla(189.78947368421052, 78.51239669421487%, 52.54901960784314%, 1) 100%)",
        "color": "rgba(255, 255, 255, 0.96)",
        "backgroundColor": "#080808"
    }
};
