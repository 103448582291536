// Generated by robots, do not change this manually!
export var lightBrand = {
    ":root": {
        "--plasma-colors-white": "#FFFFFF",
        "--plasma-colors-whitePrimary": "#FFFFFF",
        "--plasma-colors-whiteSecondary": "rgba(255, 255, 255, 0.56)",
        "--plasma-colors-whiteTertiary": "rgba(255, 255, 255, 0.28)",
        "--plasma-colors-black": "#080808",
        "--plasma-colors-blackPrimary": "#080808",
        "--plasma-colors-blackSecondary": "rgba(8, 8, 8, 0.56)",
        "--plasma-colors-blackTertiary": "rgba(8, 8, 8, 0.28)",
        "--plasma-colors-dark01": "#171717",
        "--plasma-colors-dark02": "#232323",
        "--plasma-colors-dark03": "#363636",
        "--plasma-colors-transparent": "rgba(0, 0, 0, 0)",
        "--plasma-colors-buttonClear": "rgba(0, 0, 0, 0)",
        "--plasma-colors-buttonBlack": "#080808",
        "--plasma-colors-buttonBlackSecondary": "rgba(8, 8, 8, 0.12)",
        "--plasma-colors-buttonBlackTransparent": "rgba(8, 8, 8, 0.56)",
        "--plasma-colors-buttonWhite": "#FFFFFF",
        "--plasma-colors-buttonWhiteSecondary": "rgba(255, 255, 255, 0.12)",
        "--plasma-colors-text": "#080808",
        "--plasma-colors-primary": "#080808",
        "--plasma-colors-secondary": "rgba(8, 8, 8, 0.56)",
        "--plasma-colors-tertiary": "rgba(8, 8, 8, 0.28)",
        "--plasma-colors-paragraph": "rgba(8, 8, 8, 0.8)",
        "--plasma-colors-inverse": "rgba(255, 255, 255, 0.96)",
        "--plasma-colors-background": "#FFFFFF",
        "--plasma-colors-backgroundPrimary": "#FFFFFF",
        "--plasma-colors-backgroundSecondary": "#FFFFFF",
        "--plasma-colors-backgroundTertiary": "#FFFFFF",
        "--plasma-colors-success": "#0D8523",
        "--plasma-colors-warning": "#D14D00",
        "--plasma-colors-critical": "#E31227",
        "--plasma-colors-overlay": "rgba(0, 0, 0, 0.8)",
        "--plasma-colors-surfaceLiquid01": "rgba(8, 8, 8, 0.02)",
        "--plasma-colors-surfaceLiquid02": "rgba(8, 8, 8, 0.06)",
        "--plasma-colors-surfaceLiquid03": "rgba(8, 8, 8, 0.12)",
        "--plasma-colors-surfaceSolid01": "#FAFAFA",
        "--plasma-colors-surfaceSolid02": "#F0F0F0",
        "--plasma-colors-surfaceSolid03": "#E1E1E1",
        "--plasma-colors-surfaceCard": "#FFFFFF",
        "--plasma-colors-buttonPrimary": "#080808",
        "--plasma-colors-buttonSecondary": "rgba(8, 8, 8, 0.06)",
        "--plasma-colors-buttonSuccess": "#148F2B",
        "--plasma-colors-buttonWarning": "#E35502",
        "--plasma-colors-buttonCritical": "#F31B30",
        "--plasma-colors-buttonChecked": "#080808",
        "--plasma-colors-skeletonGradient": "linear-gradient( 90deg, rgba(8, 8, 8, 0.09) 0%, rgba(8, 8, 8, 0.08) 6.25%, rgba(8, 8, 8, 0.05) 12.5%, rgba(8, 8, 8, 0.01) 25%, rgba(8, 8, 8, 0.05) 37.5%, rgba(8, 8, 8, 0.08) 43.75%, rgba(8, 8, 8, 0.09) 50%, rgba(8, 8, 8, 0.08) 56.25%, rgba(8, 8, 8, 0.05) 62.5%, rgba(8, 8, 8, 0.01) 75%, rgba(8, 8, 8, 0.05) 87.5%, rgba(8, 8, 8, 0.08) 93.75%, rgba(8, 8, 8, 0.09) 100% )",
        "--plasma-colors-skeletonGradientLighter": "linear-gradient( 90deg, rgba(8, 8, 8, 0.36) 0%, rgba(8, 8, 8, 0.32) 6.25%, rgba(8, 8, 8, 0.20) 12.5%, rgba(8, 8, 8, 0.04) 25%, rgba(8, 8, 8, 0.20) 37.5%, rgba(8, 8, 8, 0.32) 43.75%, rgba(8, 8, 8, 0.36) 50%, rgba(8, 8, 8, 0.08) 56.25%, rgba(8, 8, 8, 0.20) 62.5%, rgba(8, 8, 8, 0.04) 75%, rgba(8, 8, 8, 0.20) 87.5%, rgba(8, 8, 8, 0.32) 93.75%, rgba(8, 8, 8, 0.36) 100% )",
        "--plasma-colors-speechBubbleSent": "rgba(8, 8, 8, 0.4)",
        "--plasma-colors-speechBubbleReceived": "rgba(0, 0, 0, 0.02)",
        "--plasma-colors-accent": "#296AE3",
        "--plasma-colors-buttonAccent": "#2A72F8",
        "--plasma-colors-buttonFocused": "#2A72F8",
        "--plasma-colors-gradient": "#F5F5F5",
        "--plasma-colors-gradientDevice": "#F5F5F5",
        "--plasma-colors-voicePhraseGradient": "linear-gradient(45deg, hsla(220.1123595505618, 85.57692307692307%, 59.21568627450981%, 1) 0%, hsla(189.78947368421052, 78.51239669421487%, 52.54901960784314%, 1) 100%)",
        "color": "#080808",
        "backgroundColor": "#FFFFFF"
    }
};
