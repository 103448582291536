// Generated by robots, do not change this manually!
export var lightEva = {
    ":root": {
        "--plasma-colors-white": "#FFFFFF",
        "--plasma-colors-whitePrimary": "#FFFFFF",
        "--plasma-colors-whiteSecondary": "rgba(255, 255, 255, 0.56)",
        "--plasma-colors-whiteTertiary": "rgba(255, 255, 255, 0.28)",
        "--plasma-colors-black": "#080808",
        "--plasma-colors-blackPrimary": "#080808",
        "--plasma-colors-blackSecondary": "rgba(8, 8, 8, 0.56)",
        "--plasma-colors-blackTertiary": "rgba(8, 8, 8, 0.28)",
        "--plasma-colors-dark01": "#171717",
        "--plasma-colors-dark02": "#232323",
        "--plasma-colors-dark03": "#363636",
        "--plasma-colors-transparent": "rgba(0, 0, 0, 0)",
        "--plasma-colors-buttonClear": "rgba(0, 0, 0, 0)",
        "--plasma-colors-buttonBlack": "#080808",
        "--plasma-colors-buttonBlackSecondary": "rgba(8, 8, 8, 0.12)",
        "--plasma-colors-buttonBlackTransparent": "rgba(8, 8, 8, 0.56)",
        "--plasma-colors-buttonWhite": "#FFFFFF",
        "--plasma-colors-buttonWhiteSecondary": "rgba(255, 255, 255, 0.12)",
        "--plasma-colors-text": "#080808",
        "--plasma-colors-primary": "#080808",
        "--plasma-colors-secondary": "rgba(8, 8, 8, 0.56)",
        "--plasma-colors-tertiary": "rgba(8, 8, 8, 0.28)",
        "--plasma-colors-paragraph": "rgba(8, 8, 8, 0.8)",
        "--plasma-colors-inverse": "rgba(255, 255, 255, 0.96)",
        "--plasma-colors-background": "#FFFFFF",
        "--plasma-colors-backgroundPrimary": "#FFFFFF",
        "--plasma-colors-backgroundSecondary": "#FFFFFF",
        "--plasma-colors-backgroundTertiary": "#FFFFFF",
        "--plasma-colors-success": "#0D8523",
        "--plasma-colors-warning": "#D14D00",
        "--plasma-colors-critical": "#E31227",
        "--plasma-colors-overlay": "rgba(0, 0, 0, 0.8)",
        "--plasma-colors-surfaceLiquid01": "rgba(8, 8, 8, 0.02)",
        "--plasma-colors-surfaceLiquid02": "rgba(8, 8, 8, 0.06)",
        "--plasma-colors-surfaceLiquid03": "rgba(8, 8, 8, 0.12)",
        "--plasma-colors-surfaceSolid01": "#FAFAFA",
        "--plasma-colors-surfaceSolid02": "#F0F0F0",
        "--plasma-colors-surfaceSolid03": "#E1E1E1",
        "--plasma-colors-surfaceCard": "#FFFFFF",
        "--plasma-colors-buttonPrimary": "#080808",
        "--plasma-colors-buttonSecondary": "rgba(8, 8, 8, 0.06)",
        "--plasma-colors-buttonSuccess": "#148F2B",
        "--plasma-colors-buttonWarning": "#E35502",
        "--plasma-colors-buttonCritical": "#F31B30",
        "--plasma-colors-buttonChecked": "#080808",
        "--plasma-colors-skeletonGradient": "linear-gradient( 90deg, rgba(8, 8, 8, 0.09) 0%, rgba(8, 8, 8, 0.08) 6.25%, rgba(8, 8, 8, 0.05) 12.5%, rgba(8, 8, 8, 0.01) 25%, rgba(8, 8, 8, 0.05) 37.5%, rgba(8, 8, 8, 0.08) 43.75%, rgba(8, 8, 8, 0.09) 50%, rgba(8, 8, 8, 0.08) 56.25%, rgba(8, 8, 8, 0.05) 62.5%, rgba(8, 8, 8, 0.01) 75%, rgba(8, 8, 8, 0.05) 87.5%, rgba(8, 8, 8, 0.08) 93.75%, rgba(8, 8, 8, 0.09) 100% )",
        "--plasma-colors-skeletonGradientLighter": "linear-gradient( 90deg, rgba(8, 8, 8, 0.36) 0%, rgba(8, 8, 8, 0.32) 6.25%, rgba(8, 8, 8, 0.20) 12.5%, rgba(8, 8, 8, 0.04) 25%, rgba(8, 8, 8, 0.20) 37.5%, rgba(8, 8, 8, 0.32) 43.75%, rgba(8, 8, 8, 0.36) 50%, rgba(8, 8, 8, 0.08) 56.25%, rgba(8, 8, 8, 0.20) 62.5%, rgba(8, 8, 8, 0.04) 75%, rgba(8, 8, 8, 0.20) 87.5%, rgba(8, 8, 8, 0.32) 93.75%, rgba(8, 8, 8, 0.36) 100% )",
        "--plasma-colors-speechBubbleSent": "rgba(8, 8, 8, 0.4)",
        "--plasma-colors-speechBubbleReceived": "rgba(0, 0, 0, 0.02)",
        "--plasma-colors-accent": "#1274B5",
        "--plasma-colors-buttonAccent": "#067DCC",
        "--plasma-colors-buttonFocused": "#067DCC",
        "--plasma-colors-gradient": "linear-gradient(336.9deg, rgba(0, 224, 255, 0.06) 0%, rgba(255, 255, 255, 0) 64.95%), radial-gradient(66.53% 100% at 73.33% 100%, rgba(0, 87, 255, 0.04) 0%, rgba(87, 8, 255, 0.01) 99.69%), linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, rgba(0, 224, 255, 0.06) 100%), linear-gradient(270deg, rgba(0, 71, 253, 0.02) 0%, rgba(255, 255, 255, 0) 100%)",
        "--plasma-colors-gradientDevice": "linear-gradient(26.05deg, rgba(26, 178, 255, 0.08) 0%, rgba(255, 255, 255, 0) 72.24%), radial-gradient(100% 100% at 0% 100%, rgba(0, 170, 255, 0.04) 0%, rgba(255, 255, 255, 0) 99.69%), radial-gradient(74.68% 149.35% at 50% 149.35%, rgba(128, 0, 255, 0.08) 0%, rgba(255, 255, 255, 0) 99.69%)",
        "--plasma-colors-voicePhraseGradient": "linear-gradient(45deg, hsla(200.1, 78.74015748031496%, 49.803921568627445%, 1) 0%, hsla(240, 100%, 73.92156862745098%, 1) 100%)",
        "color": "#080808",
        "backgroundColor": "#FFFFFF"
    }
};
