export const RULES_TOP3 = {
  exactly3: {
    firstTicket: {
      restrictions: {
        min: 3,
        max: 3,
      },
      rows: 10,
      cols: 3,
    },
    secondTicket: null,
    limitationInRow: 3,
    colsLimitation: [],
    rusName: "Точно 3",
    gameRulesText: "Нужно угадать 3 выпавших числа в точном порядке",
    modeId: 4,
  },
  any3: {
    firstTicket: {
      restrictions: {
        min: 3,
        max: 3,
      },
      rows: 10,
      cols: 3,
    },
    secondTicket: null,
    limitationInRow: 2,
    colsLimitation: [],
    rusName: "Любые 3",
    gameRulesText: "Нужно угадать 3 выпавших числа в любом порядке",
    modeId: 7,
  },
  exactly3Plusany3: {
    firstTicket: {
      restrictions: {
        min: 3,
        max: 3,
      },
      rows: 10,
      cols: 3,
    },
    secondTicket: null,
    limitationInRow: 2,
    colsLimitation: [],
    rusName: "Точно 3 + Любые 3",
    gameRulesText:
      "Нужно угадать 3 выпавших числа в точном порядке либо 3 выпавших числа в любом порядке. В отличие от «Точно 3» и «Любые 3», выигрыш может быть увеличен",
    modeId: 8,
  },
  first2: {
    firstTicket: {
      restrictions: {
        min: 2,
        max: 2,
      },
      rows: 10,
      cols: 3,
    },
    secondTicket: null,
    limitationInRow: 2,
    colsLimitation: [2],
    rusName: "Первые 2 числа",
    gameRulesText: "Нужно угадать первые 2 выпавших числа",
    modeId: 5,
  },
  last2: {
    firstTicket: {
      restrictions: {
        min: 2,
        max: 2,
      },
      rows: 10,
      cols: 3,
    },
    secondTicket: null,
    limitationInRow: 2,
    colsLimitation: [0],
    rusName: "Последние 2 числа",
    gameRulesText: "Нужно угадать последние 2 выпавших числа",
    modeId: 6,
  },
  any2: {
    firstTicket: {
      restrictions: {
        min: 2,
        max: 2,
      },
      rows: 10,
      cols: 3,
    },
    secondTicket: null,
    limitationInRow: 2,
    colsLimitation: [],
    rusName: "Любые 2",
    gameRulesText: "Нужно угадать 2 числа в любом порядке",
    modeId: 2,
  },
  exactly1: {
    firstTicket: {
      restrictions: {
        min: 1,
        max: 1,
      },
      rows: 10,
      cols: 3,
    },
    secondTicket: null,
    limitationInRow: 1,
    colsLimitation: [],
    rusName: "Точно 1",
    gameRulesText: "Нужно угадать 1 число и его расположение",
    modeId: 1,
  },
  combo: {
    firstTicket: {
      restrictions: {
        min: 3,
        max: 3,
      },
      rows: 10,
      cols: 3,
    },
    secondTicket: null,
    limitationInRow: 2,
    colsLimitation: [],
    rusName: "Комбо",
    gameRulesText:
      "Отметьте 1 + 2 одинаковых числа (Комбо 3) или 3 разных числа (Комбо 6). Выигрыш в обоих случаях возможен, если выпадет комбинация из любых отмеченных вами чисел",
    modeId: 10,
  },
};
export const SCREEN_KENO = {
  firstGame: [
    { rusName: "Четных", key: "even", id: "EVEN" },
    { rusName: "Нечетных", key: "notEven", id: "ODD" },
    { rusName: "Поровну", key: "equally", id: "FIFTY_FIFTY" },
  ],

  secondGame: {
    firstTicket: {
      firstTicketRule: "Выберите от 1 до 10 чисел",
      restrictions: {
        min: 1,
        max: 10,
      },
      sberBox: { rows: 7, cols: 12, maxSquareViewed: 80 },
      sberPortal: { rows: 8, cols: 10, maxSquareViewed: 80 },
      mobile: { rows: 10, cols: 8, maxSquareViewed: 80 },
    },
  },
  threeGame: {
    firstTicket: {
      restrictions: {
        min: 1,
        max: 10,
      },
      rows: 8,
      cols: 10,
      maxSquareViewed: 80,
    },
  },
};
export const ALLOWED_VARIABLES_4x20 = [
  "0_0",
  "1_0",
  "2_0",
  "3_0",
  "4_0",
  "5_0",
  "6_0",
  "7_0",
  "8_0",
  "9_0",
  "10_0",
  "11_0",
  "12_0",
  "1_1",
  "2_1",
  "3_1",
  "4_1",
  "5_1",
  "6_1",
  "7_1",
  "8_1",
  "9_1",
  "10_1",
  "11_1",
  "12_1",
  "1_2",
  "2_2",
  "2_3",
  "2_4",
  "2_5",
  "2_6",
  "2_7",
  "2_8",
  "2_9",
  "2_10",
  "2_11",
  "2_12",
  "3_1",
  "3_2",
  "3_3",
  "3_4",
  "3_5",
  "3_6",
  "3_7",
  "3_8",
  "3_9",
  "3_10",
  "3_11",
  "3_12",
  "4_1",
  "4_2",
  "4_3",
  "4_4",
  "4_5",
  "4_6",
  "4_7",
  "4_8",
  "4_9",
  "4_10",
  "4_11",
  "4_12",
  "4_4",
  "4_5",
  "4_6",
  "5_5",
  "4_7",
  "4_8",
  "5_6",
  "4_9",
  "5_7",
  "4_10",
  "6_6",
  "4_11",
  "5_8",
  "4_12",
  "6_7",
  "5_9",
  "4_13",
  "4_14",
  "5_10",
  "6_8",
  "7_7",
  "4_15",
  "5_11",
  "4_16",
  "6_9",
];

export const ALLOWED_VARIABLES_DUEL = [
  "0_0",
  "1_0",
  "2_0",
  "3_0",
  "4_0",
  "5_0",
  "6_0",
  "7_0",
  "8_0",
  "9_0",
  "10_0",
  "11_0",
  "12_0",
  "13_0",
  "14_0",
  "15_0",
  "16_0",
  "17_0",
  "18_0",
  "19_0",
  "20_0",
  "21_0",
  "22_0",
  "23_0",
  "24_0",
  "25_0",
  "26_0",

  "1_1",
  "2_1",
  "3_1",
  "4_1",
  "5_1",
  "6_1",
  "7_1",
  "8_1",
  "9_1",
  "10_1",
  "11_1",
  "12_1",
  "13_1",
  "14_1",
  "15_1",
  "16_1",
  "17_1",
  "18_1",
  "19_1",
  "20_1",
  "21_1",
  "22_1",
  "23_1",
  "24_1",
  "25_1",
  "26_1",

  "2_2",
  "2_3",
  "2_4",
  "2_5",
  "2_6",
  "2_7",
  "2_8",
  "2_9",
  "2_10",
  "2_11",
  "2_12",
  "2_13",
  "2_14",
  "2_15",
  "2_16",
  "2_17",
  "2_18",
  "2_19",
  "2_20",
  "2_21",
  "2_22",
  "2_23",
  "2_24",
  "2_25",
  "2_26",

  "3_3",
  "3_4",
  "3_5",
  "3_6",
  "3_7",
  "3_8",
  "3_9",
  "3_10",
  "3_11",
  "3_12",
  "3_13",
  "3_14",
  "3_15",
  "3_16",
  "3_17",
  "3_18",
  "3_19",
  "3_20",
  "3_21",
  "3_22",
  "3_23",
  "3_24",
  "3_25",
  "3_26",

  "4_4",
  "4_5",
  "4_6",
  "4_7",
  "4_8",
  "4_9",
  "4_10",
  "4_11",
  "4_12",
  "4_13",
  "4_14",
  "4_15",
  "4_16",
  "4_17",
  "4_18",
  "4_19",
  "4_20",
  "4_21",
  "4_22",
  "4_23",
  "4_24",
  "4_25",
  "4_26",

  "5_5",
  "5_6",
  "5_7",
  "5_8",
  "5_9",
  "5_10",
  "5_11",
  "5_12",
  "5_13",
  "5_14",
  "5_15",
  "5_16",
  "5_17",
  "5_18",
  "5_19",
  "5_20",
  "5_21",
  "5_22",
  "5_23",
  "5_24",
  "5_25",
  "5_26",

  "6_6",
  "6_7",
  "6_8",
  "6_9",
  "6_10",
  "6_11",
  "6_12",
  "6_13",
  "6_14",
  "6_15",
  "6_16",
  "6_17",
  "6_18",
  "6_19",
  "6_20",
  "6_21",
  "6_22",
  "6_23",
  "6_24",
  "6_25",
  "6_26",

  "7_7",
  "7_8",
  "7_9",
  "7_10",
  "7_11",
  "7_12",
  "7_13",
  "7_14",
  "7_15",
  "7_16",
  "7_17",
  "7_18",
  "7_19",
  "7_20",
  "7_21",
  "7_22",
  "7_23",
  "7_24",
  "7_25",
  "7_26",

  "8_8",
  "8_9",
  "8_10",
  "8_11",
  "8_12",
  "8_13",
  "8_14",
  "8_15",
  "8_16",
  "8_17",
  "8_18",
  "8_19",
  "8_20",
  "8_21",
  "8_22",
  "8_23",
  "8_24",
  "8_25",
  "8_26",

  "9_9",
  "9_10",
  "9_11",
  "9_12",
  "9_13",
  "9_14",
  "9_15",
  "9_16",
  "9_17",
  "9_18",
  "9_19",
  "9_20",
  "9_21",
  "9_22",
  "9_23",
  "9_24",

  "10_10",
  "10_11",
  "10_12",
  "10_13",
  "10_14",
  "10_15",
  "10_16",
  "10_17",
  "10_18",
  "10_19",
  "10_20",
  "10_21",

  "11_11",
  "11_12",
  "11_13",

  "12_12",
  "13_13",
  "14_14",
];

export const MAIN_LOTTO_RULES = {
  "6x45": {
    ticketRule: "Выберите минимум 6 чисел",
    firstTicket: {
      firstTicketRule: null,
      restrictions: {
        min: 6,
        max: 16,
      },
      sberBox: { rows: 5, cols: 11, maxSquareViewed: 45 },
      sberPortal: { rows: 6, cols: 9, maxSquareViewed: 45 },
      mobile: { rows: 7, cols: 7, maxSquareViewed: 45 },
    },
    secondTicket: null,
    multipleerTable: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 1,
      7: 7,
      8: 28,
      9: 84,
      10: 210,
      11: 462,
      12: 924,
      13: 1716,
      14: 3003,
      15: 5005,
      16: 8008,
    },
  },
  "7x49": {
    ticketRule: "Выберите минимум 6 чисел",
    firstTicket: {
      firstTicketRule: null,
      restrictions: {
        min: 7,
        max: 13,
      },
      sberBox: { rows: 5, cols: 11, maxSquareViewed: 49 },
      sberPortal: { rows: 6, cols: 9, maxSquareViewed: 49 },
      mobile: { rows: 7, cols: 7, maxSquareViewed: 49 },
    },
    secondTicket: null,
    multipleerTable: {
      0: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 1,
      8: 8,
      9: 36,
      10: 120,
      11: 330,
      12: 792,
      13: 1716,
    },
  },
  "4x20": {
    ticketRule: "Отметьте минимум 4 числа",
    firstTicket: {
      firstTicketRule: null,
      restrictions: {
        min: 4,
        max: 7,
      },
      sberBox: { rows: 5, cols: 4, maxSquareViewed: 20 },
      sberPortal: { rows: 5, cols: 4, maxSquareViewed: 20 },
      mobile: { rows: 7, cols: 3, maxSquareViewed: 20 },
    },
    secondTicket: {
      secondTicketRule: null,
      restrictions: {
        min: 4,
        max: 7,
      },
      sberBox: { rows: 5, cols: 4, maxSquareViewed: 20 },
      sberPortal: { rows: 5, cols: 4, maxSquareViewed: 20 },
      mobile: { rows: 7, cols: 3, maxSquareViewed: 20 },
    },
    multipleerTable: {
      "0_0": 0,
      "0_1": 0,
      "0_2": 0,
      "0_3": 0,
      "0_4": 0,
      "1_4": 0,
      "2_4": 0,
      "3_4": 0,
      "4_4": 1,
      "4_5": 5,
      "4_6": 15,
      "5_5": 25,
      "4_7": 35,
      "4_8": 70,
      "5_6": 75,
      "4_9": 126,
      "5_7": 175,
      "4_10": 210,
      "6_6": 225,
      "4_11": 330,
      "5_8": 350,
      "4_12": 495,
      "6_7": 525,
      "5_9": 630,
      "4_13": 715,
      "4_14": 1001,
      "5_10": 1050,
      "6_8": 1050,
      "7_7": 1225,
      "4_15": 1365,
      "5_11": 1650,
      "4_16": 1820,
      "6_9": 1890,
    },
  },
  rapido: {
    ticketRule: null,
    firstTicket: {
      firstTicketRule: "Выберите минимум 8 чисел",
      restrictions: {
        min: 8,
        max: 12,
      },
      sberBox: { rows: 4, cols: 5, maxSquareViewed: 20 },
      sberPortal: { rows: 3, cols: 8, maxSquareViewed: 20 },
      mobile: { rows: 3, cols: 7, maxSquareViewed: 20 },
    },
    secondTicket: {
      secondTicketRule: "Выберите минимум 1 число",
      restrictions: {
        min: 1,
        max: 4,
      },
      sberBox: { rows: 1, cols: 4, maxSquareViewed: 4 },
      sberPortal: { rows: 1, cols: 4, maxSquareViewed: 4 },
      mobile: { rows: 1, cols: 4, maxSquareViewed: 4 },
    },
    multipleerTable: {
      "0_0": 0,
      "0_1": 0,
      "0_2": 0,
      "0_3": 0,
      "0_4": 0,
      "0_5": 0,
      "0_6": 0,
      "0_7": 0,
      "0_8": 0,

      "1_1": 0,
      "1_2": 0,
      "1_3": 0,
      "1_4": 0,
      "1_5": 0,
      "1_6": 0,
      "1_7": 0,

      "2_1": 0,
      "2_2": 0,
      "2_3": 0,
      "2_4": 0,
      "2_5": 0,
      "2_6": 0,
      "2_7": 0,

      "3_1": 0,
      "3_2": 0,
      "3_3": 0,
      "3_4": 0,
      "3_5": 0,
      "3_6": 0,
      "3_7": 0,

      "4_1": 0,
      "4_2": 0,
      "4_3": 0,
      "4_4": 0,
      "4_5": 0,
      "4_6": 0,
      "4_7": 0,

      "8_1": 1,
      "8_2": 2,
      "8_3": 3,
      "8_4": 4,

      "9_1": 9,
      "9_2": 18,
      "9_3": 27,
      "9_4": 36,

      "10_1": 45,
      "10_2": 90,
      "10_3": 135,
      "10_4": 180,

      "11_1": 165,
      "11_2": 330,
      "11_3": 495,
      "11_4": 660,

      "12_1": 495,
      "12_2": 990,
      "12_3": 1486,
      "12_4": 1980,
    },
  },
  1224: {
    ticketRule: "Выберите 12 чисел в игровом поле",
    firstTicket: {
      firstTicketRule: null,
      restrictions: {
        min: 12,
        max: 12,
      },
      sberBox: { rows: 3, cols: 8, maxSquareViewed: 24 },
      sberPortal: { rows: 3, cols: 8, maxSquareViewed: 24 },
      mobile: { rows: 4, cols: 7, maxSquareViewed: 24 },
    },
    secondTicket: null,
    multipleerTable: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 1,
    },
  },
  "5x36plus": {
    ticketRule: null,
    firstTicket: {
      firstTicketRule: "Выберите минимум 5 чисел",
      restrictions: {
        min: 5,
        max: 11,
      },
      sberBox: { rows: 4, cols: 11, maxSquareViewed: 36 },
      sberPortal: { rows: 5, cols: 9, maxSquareViewed: 36 },
      mobile: { rows: 5, cols: 8, maxSquareViewed: 36 },
    },
    secondTicket: {
      secondTicketRule: "Выберите минимум 1 число",
      restrictions: {
        min: 1,
        max: 4,
      },
      sberBox: { rows: 1, cols: 4, maxSquareViewed: 4 },
      sberPortal: { rows: 1, cols: 4, maxSquareViewed: 4 },
      mobile: { rows: 1, cols: 4, maxSquareViewed: 4 },
    },
    multipleerTable: {
      "0_0": 0,
      "0_1": 0,
      "0_2": 0,
      "0_3": 0,
      "0_4": 0,
      "0_5": 0,

      "1_5": 1,
      "2_5": 2,
      "3_5": 3,
      "4_5": 4,

      "1_6": 6,
      "2_6": 12,
      "3_6": 18,
      "4_6": 24,

      "1_7": 21,
      "2_7": 42,
      "3_7": 63,
      "4_7": 84,

      "1_8": 56,
      "2_8": 112,
      "3_8": 168,
      "4_8": 224,

      "1_9": 126,
      "2_9": 252,
      "3_9": 378,
      "4_9": 504,

      "1_10": 252,
      "2_10": 504,
      "3_10": 756,
      "4_10": 1008,

      "1_11": 462,
      "2_11": 924,
      "3_11": 1386,
      "4_11": 1848,
    },
  },
  duel: {
    ticketRule: "Отметьте минимум 2 числа",
    firstTicket: {
      firstTicketRule: null,
      restrictions: {
        min: 2,
        max: 20,
      },
      sberBox: { rows: 7, cols: 4, maxSquareViewed: 26 },
      sberPortal: { rows: 8, cols: 4, maxSquareViewed: 26 },
      mobile: { rows: 9, cols: 3, maxSquareViewed: 26 },
    },
    secondTicket: {
      secondTicketRule: null,
      restrictions: {
        min: 2,
        max: 20,
      },
      sberBox: { rows: 7, cols: 4, maxSquareViewed: 26 },
      sberPortal: { rows: 8, cols: 4, maxSquareViewed: 26 },
      mobile: { rows: 9, cols: 3, maxSquareViewed: 26 },
    },
    multipleerTable: {
      "0_0": 0,
      "1_0": 0,
      "2_0": 0,
      "3_0": 0,
      "4_0": 0,
      "5_0": 0,
      "6_0": 0,
      "7_0": 0,
      "8_0": 0,
      "9_0": 0,
      "10_0": 0,
      "11_0": 0,
      "12_0": 0,
      "13_0": 0,
      "14_0": 0,
      "15_0": 0,
      "16_0": 0,
      "17_0": 0,
      "18_0": 0,
      "19_0": 0,
      "20_0": 0,
      "21_0": 0,
      "22_0": 0,
      "23_0": 0,
      "24_0": 0,
      "25_0": 0,
      "26_0": 0,

      "1_1": 0,
      "2_1": 0,
      "3_1": 0,
      "4_1": 0,
      "5_1": 0,
      "6_1": 0,
      "7_1": 0,
      "8_1": 0,
      "9_1": 0,
      "10_1": 0,
      "11_1": 0,
      "12_1": 0,
      "13_1": 0,
      "14_1": 0,
      "15_1": 0,
      "16_1": 0,
      "17_1": 0,
      "18_1": 0,
      "19_1": 0,
      "20_1": 0,
      "21_1": 0,
      "22_1": 0,
      "23_1": 0,
      "24_1": 0,
      "25_1": 0,
      "26_1": 0,

      "2_2": 1,
      "2_3": 3,
      "2_4": 6,
      "2_5": 10,
      "2_6": 15,
      "2_7": 21,
      "2_8": 28,
      "2_9": 36,
      "2_10": 45,
      "2_11": 55,
      "2_12": 66,
      "2_13": 78,
      "2_14": 91,
      "2_15": 105,
      "2_16": 120,
      "2_17": 136,
      "2_18": 153,
      "2_19": 171,
      "2_20": 190,
      "2_21": 210,
      "2_22": 231,
      "2_23": 253,
      "2_24": 276,
      "2_25": 300,
      "2_26": 325,

      "3_3": 9,
      "3_4": 18,
      "3_5": 30,
      "3_6": 45,
      "3_7": 63,
      "3_8": 84,
      "3_9": 108,
      "3_10": 135,
      "3_11": 165,
      "3_12": 198,
      "3_13": 234,
      "3_14": 273,
      "3_15": 315,
      "3_16": 360,
      "3_17": 408,
      "3_18": 459,
      "3_19": 513,
      "3_20": 570,
      "3_21": 630,
      "3_22": 693,
      "3_23": 759,
      "3_24": 828,
      "3_25": 900,
      "3_26": 975,

      "4_4": 36,
      "4_5": 60,
      "4_6": 90,
      "4_7": 126,
      "4_8": 168,
      "4_9": 216,
      "4_10": 270,
      "4_11": 330,
      "4_12": 396,
      "4_13": 468,
      "4_14": 546,
      "4_15": 630,
      "4_16": 720,
      "4_17": 816,
      "4_18": 918,
      "4_19": 1026,
      "4_20": 1140,
      "4_21": 1260,
      "4_22": 1386,
      "4_23": 1518,
      "4_24": 1656,
      "4_25": 1800,
      "4_26": 1950,

      "5_5": 100,
      "5_6": 150,
      "5_7": 210,
      "5_8": 280,
      "5_9": 360,
      "5_10": 450,
      "5_11": 550,
      "5_12": 660,
      "5_13": 780,
      "5_14": 910,
      "5_15": 1050,
      "5_16": 1200,
      "5_17": 1360,
      "5_18": 1530,
      "5_19": 1710,
      "5_20": 1900,
      "5_21": 2100,
      "5_22": 2310,
      "5_23": 2530,
      "5_24": 2760,
      "5_25": 3000,
      "5_26": 3250,

      "6_6": 225,
      "6_7": 315,
      "6_8": 420,
      "6_9": 540,
      "6_10": 675,
      "6_11": 825,
      "6_12": 990,
      "6_13": 1170,
      "6_14": 1365,
      "6_15": 1575,
      "6_16": 1800,
      "6_17": 2040,
      "6_18": 2295,
      "6_19": 2565,
      "6_20": 2850,
      "6_21": 3150,
      "6_22": 3465,
      "6_23": 3795,
      "6_24": 4140,
      "6_25": 4500,
      "6_26": 4875,

      "7_7": 441,
      "7_8": 588,
      "7_9": 756,
      "7_10": 945,
      "7_11": 1155,
      "7_12": 1386,
      "7_13": 1638,
      "7_14": 1911,
      "7_15": 2205,
      "7_16": 2520,
      "7_17": 2856,
      "7_18": 3213,
      "7_19": 3591,
      "7_20": 3990,
      "7_21": 4410,
      "7_22": 4851,
      "7_23": 5313,
      "7_24": 5796,
      "7_25": 6300,
      "7_26": 6825,

      "8_8": 784,
      "8_9": 1008,
      "8_10": 1260,
      "8_11": 1540,
      "8_12": 1848,
      "8_13": 2184,
      "8_14": 2548,
      "8_15": 2940,
      "8_16": 3360,
      "8_17": 3808,
      "8_18": 4284,
      "8_19": 4788,
      "8_20": 5320,
      "8_21": 5880,
      "8_22": 6468,
      "8_23": 7084,
      "8_24": 7728,
      "8_25": 8400,
      "8_26": 9100,

      "9_9": 1296,
      "9_10": 1620,
      "9_11": 1980,
      "9_12": 2376,
      "9_13": 2808,
      "9_14": 3276,
      "9_15": 3780,
      "9_16": 4320,
      "9_17": 4896,
      "9_18": 5508,
      "9_19": 6156,
      "9_20": 6840,
      "9_21": 7560,
      "9_22": 8316,
      "9_23": 9108,
      "9_24": 9936,

      "10_10": 2025,
      "10_11": 2475,
      "10_12": 2970,
      "10_13": 3510,
      "10_14": 4095,
      "10_15": 4725,
      "10_16": 5400,
      "10_17": 6120,
      "10_18": 6885,
      "10_19": 7695,
      "10_20": 8550,
      "10_21": 9450,

      "11_11": 3025,
      "11_12": 3630,
      "11_13": 4290,

      "12_12": 4356,
      "13_13": 6084,
      "14_14": 8281,
    },
  },
  "5x2": {
    ticketRule: null,
    firstTicket: {
      firstTicketRule: "Выберите минимум 5 чисел",
      restrictions: {
        min: 5,
        max: 16,
      },
      sberBox: { rows: 5, cols: 11, maxSquareViewed: 50 },
      sberPortal: { rows: 5, cols: 10, maxSquareViewed: 50 },
      mobile: { rows: 7, cols: 8, maxSquareViewed: 50 },
    },
    secondTicket: {
      secondTicketRule: "Выберите минимум 2 числа",
      restrictions: {
        min: 2,
        max: 10,
      },
      sberBox: { rows: 1, cols: 10, maxSquareViewed: 10 },
      sberPortal: { rows: 1, cols: 10, maxSquareViewed: 10 },
      mobile: { rows: 2, cols: 8, maxSquareViewed: 10 },
    },
    multipleerTable: {
      "5_2": 1,
      "5_3": 3,
      "5_4": 6,
      "5_5": 10,
      "5_6": 15,
      "5_7": 21,
      "5_8": 28,
      "5_9": 36,
      "5_10": 45,

      "6_2": 6,
      "6_3": 18,
      "6_4": 36,
      "6_5": 60,
      "6_6": 90,
      "6_7": 126,
      "6_8": 168,
      "6_9": 216,
      "6_10": 270,

      "7_2": 21,
      "7_3": 63,
      "7_4": 126,
      "7_5": 210,
      "7_6": 315,
      "7_7": 441,
      "7_8": 588,
      "7_9": 756,
      "7_10": 945,

      "8_2": 56,
      "8_3": 168,
      "8_4": 336,
      "8_5": 560,
      "8_6": 840,
      "8_7": 1176,
      "8_8": 1568,
      "8_9": 2016,
      "8_10": 2520,

      "9_2": 126,
      "9_3": 378,
      "9_4": 756,
      "9_5": 1260,
      "9_6": 1890,
      "9_7": 2646,
      "9_8": 3528,
      "9_9": 4536,
      "9_10": 5670,

      "10_2": 252,
      "10_3": 756,
      "10_4": 1512,
      "10_5": 2520,
      "10_6": 3780,
      "10_7": 5292,

      "11_2": 462,
      "11_3": 1386,
      "11_4": 2772,
      "11_5": 4620,

      "12_2": 792,
      "12_3": 2376,
      "12_4": 4752,

      "13_2": 1287,
      "13_3": 3861,

      "14_2": 2002,
      "15_2": 3003,
      "16_2": 4368,
    },
  },
};

MAIN_LOTTO_RULES.zabava = MAIN_LOTTO_RULES["1224"];
MAIN_LOTTO_RULES.dvazhdydva = MAIN_LOTTO_RULES["duel"];
MAIN_LOTTO_RULES.oxota = MAIN_LOTTO_RULES["4x20"];
MAIN_LOTTO_RULES["rapido-drive"] = MAIN_LOTTO_RULES["rapido"];
MAIN_LOTTO_RULES["rapido-ultra"] = MAIN_LOTTO_RULES["rapido"];
MAIN_LOTTO_RULES["rapido2"] = MAIN_LOTTO_RULES["rapido"];

export const GAMES = [
  {
    id: "4x20",
    rusName: "Спортлото 4 из 20",
    gameLogo: "4x20.png",
  },
  {
    id: "oxota",
    rusName: "Охота",
    gameLogo: "oxota.png",
  },
  {
    id: "6x45",
    rusName: "Спортлото 6 из 45",
    gameLogo: "6x45.png",
  },
  {
    id: "5x36plus",
    rusName: "Спортлото 5 из 36",
    gameLogo: "5x36plus.png",
  },
  {
    id: "7x49",
    rusName: "Спортлото 7 из 49",
    gameLogo: "7x49.png",
  },
  {
    id: "ruslotto",
    rusName: "Русское лото",
    gameLogo: "ruslotto.png",
  },
  {
    id: "express",
    rusName: "Лото экспресс",
    gameLogo: "express.png",
  },
  {
    id: "bingo75",
    rusName: "Бинго-75",
    gameLogo: "bingo75.png",
  },
  {
    id: "gzhl",
    rusName: "Жилищная лотерея",
    gameLogo: "gzhl.png",
  },
  {
    id: "zp",
    rusName: "Золотая подкова",
    gameLogo: "zp.png",
  },
  {
    id: "6x36",
    rusName: "Спортлото 6 из 36",
    gameLogo: "6x36.png",
  },

  {
    id: "top3",
    rusName: "ТОП 3",
    gameLogo: "top3.png",
  },
  {
    id: "rapido",
    rusName: "Рапидо",
    gameLogo: "rapido.png",
  },
  {
    id: "rapido2",
    rusName: "Рапидо 2.0",
    gameLogo: "rapido2.png",
  },
  {
    id: "rapido-drive",
    rusName: "Рапидо Драйв",
    gameLogo: "rapido-drive.png",
  },
  {
    id: "rapido-ultra",
    rusName: "Рапидо Ультра",
    gameLogo: "rapido-ultra.png",
  },
  {
    id: "keno2",
    rusName: "КЕНО",
    gameLogo: "keno2.png",
  },
  {
    id: "1224",
    rusName: "Всё или ничего",
    gameLogo: "1224.png",
  },
  {
    id: "duel",
    rusName: "Дуэль",
    gameLogo: "duel.png",
  },

  {
    id: "rocketbingo",
    rusName: "Рокетбинго",
    gameLogo: "rocketbingo.png",
  },
  {
    id: "zabava",
    rusName: "Забава от Русского лото",
    gameLogo: "zabava.png",
  },
  {
    id: "dvazhdydva",
    rusName: "Проще, чем дважды два",
    gameLogo: "dvazhdydva.png",
  },
  {
    id: "5x2",
    rusName: "Большое спортлото",
    gameLogo: "5x2.png",
  },
];

export const BUTTON_STYLES = {
  rocketbingo: {
    mobile: {
      borderRadius: "9px",
      height: "28px",
      width: "52px",
      fontSize: "14px",
      buttonPadding: "1px",
    },

    sberBox: {
      borderRadius: "16px",
      height: "48px",
      width: "84px",
      fontSize: "25px",
      buttonPadding: "2px",
    },
    sberPortal: {
      borderRadius: "10px",
      height: "1.5em",
      width: "2.4em",
      fontSize: "24px",
      buttonPadding: "0.03em",
    },
  },
  bingo75: {
    mobile: {
      borderRadius: "9px",
      height: "28px",
      width: "52px",
      fontSize: "14px",
      buttonPadding: "1px",
    },

    sberBox: {
      borderRadius: "16px",
      height: "48px",
      width: "84px",
      fontSize: "25px",
      buttonPadding: "2px",
    },
    sberPortal: {
      borderRadius: "12px",
      height: "40px",
      width: "56px",
      fontSize: "24px",
      buttonPadding: "3px",
    },
  },
  zp: {
    mobile: {
      borderRadius: "10px",
      height: "30px",
      width: "30px",
      fontSize: "14px",
      buttonPadding: "0px",
    },

    sberBox: {
      borderRadius: "12px",
      height: "1.5em",
      width: "2.65em",
      fontSize: "25px",
      buttonPadding: "2px",
    },
    sberPortal: {
      borderRadius: "9px",
      height: "1em",
      width: "2.5em",
      fontSize: "20px",
      buttonPadding: "1px",
    },
  },
  gzhl: {
    mobile: {
      borderRadius: "10px",
      height: "30px",
      width: "30px",
      fontSize: "14px",
      buttonPadding: "0px",
    },

    sberBox: {
      borderRadius: "12px",
      height: "1.5em",
      width: "2.65em",
      fontSize: "25px",
      buttonPadding: "2px",
    },
    sberPortal: {
      borderRadius: "12px",
      height: "32px",
      width: "52px",
      fontSize: "20px",
      buttonPadding: "1px",
    },
  },
  ruslotto: {
    mobile: {
      borderRadius: "10px",
      height: "30px",
      width: "30px",
      fontSize: "14px",
      buttonPadding: "0px",
    },

    sberBox: {
      borderRadius: "12px",
      height: "1.5em",
      width: "2.65em",
      fontSize: "25px",
      buttonPadding: "2px",
    },
    sberPortal: {
      borderRadius: "9px",
      height: "1em",
      width: "2.5em",
      fontSize: "20px",
      buttonPadding: "1px",
    },
  },
  express: {
    mobile: {
      borderRadius: "10px",
      height: "30px",
      width: "30px",
      fontSize: "14px",
      buttonPadding: "0px",
    },

    sberBox: {
      borderRadius: "12px",
      height: "1.5em",
      width: "2.65em",
      fontSize: "25px",
      buttonPadding: "2px",
    },
    sberPortal: {
      borderRadius: "9px",
      height: "1em",
      width: "2.9em",
      fontSize: "20px",
      buttonPadding: "1px",
    },
  },
  "6x36": {
    mobile: {
      borderRadius: "9px",
      height: "30px",
      width: "42px",
      fontSize: "14px",
      buttonPadding: "1px",
    },
    sberBox: {
      borderRadius: "12px",
      height: "44px",
      width: "60px",
      fontSize: "23px",
      buttonPadding: "1px",
    },
    sberPortal: {
      borderRadius: "11px",
      height: "36px",
      width: "52px",
      fontSize: "22px",
      buttonPadding: "1px",
    },
  },
};

export const TABLE_STYLES = {
  sberPortal: {
    rocketbingo: {
      marginLeft: "0.5em",
      marginBottom: "0.5em",
      marginRight: "0.5em",
      marginTop: "0.5em",
    },
    bingo75: {
      marginLeft: "0.2em",
      marginBottom: "0.5em",
      marginRight: "0.2em",
      marginTop: "0.2em",
    },
    "6x36": {
      marginLeft: "0.2em",
      marginBottom: "0.2em",
      marginRight: "0.2em",
      marginTop: "0.1em",
    },
    gzhl: {
      marginLeft: "0.2em",
      marginBottom: "0.2em",
      marginRight: "0.2em",
      marginTop: "0.2em",
    },
    zp: {
      marginLeft: "0.2em",
      marginBottom: "0.2em",
      marginRight: "0.3em",
      marginTop: "0.2em",
    },
    ruslotto: {
      marginLeft: "0.2em",
      marginBottom: "0.2em",
      marginRight: "0.3em",
      marginTop: "0.2em",
    },
    express: {
      marginLeft: "0.2em",
      marginBottom: "0.2em",
      marginRight: "0.3em",
      marginTop: "0.2em",
    },
  },
  mobile: {
    rocketbingo: {
      marginLeft: "0.7em",
      marginBottom: "0.5em",
      marginRight: "0.6em",
      marginTop: "-0.5em",
    },
    bingo75: {
      marginLeft: "0.75em",
      marginBottom: "0.5em",
      marginRight: "0.75em",
      marginTop: "-0.5em",
    },
    "6x36": {
      marginLeft: "0.66em",
      marginBottom: "0.5em",
      marginRight: "0.66em",
      marginTop: "-0.7em",
    },
    gzhl: {
      marginLeft: "5px",
      marginBottom: "10px",
      marginRight: "5px",
      marginTop: "-5px",
    },
    zp: {
      marginLeft: "5px",
      marginBottom: "10px",
      marginRight: "5px",
      marginTop: "-5px",
    },
    ruslotto: {
      marginLeft: "5px",
      marginBottom: "10px",
      marginRight: "5px",
      marginTop: "-5px",
    },
    express: {
      marginLeft: "5px",
      marginBottom: "10px",
      marginRight: "5px",
      marginTop: "-5px",
    },
  },
  sberBox: {
    rocketbingo: {
      marginLeft: "30px",
      marginBottom: "27px",
      marginRight: "30px",
      marginTop: "27px",
    },
    bingo75: {
      marginLeft: "30px",
      marginBottom: "27px",
      marginRight: "30px",
      marginTop: "27px",
    },
    "6x36": {
      marginLeft: "20px",
      marginBottom: "27px",
      marginRight: "20px",
      marginTop: "27px",
    },
    gzhl: {
      marginLeft: "20px",
      marginBottom: "27px",
      marginRight: "20px",
      marginTop: "27px",
    },
    zp: {
      marginLeft: "20px",
      marginBottom: "27px",
      marginRight: "20px",
      marginTop: "27px",
    },
    ruslotto: {
      marginLeft: "20px",
      marginBottom: "27px",
      marginRight: "20px",
      marginTop: "27px",
    },
    express: {
      marginLeft: "20px",
      marginBottom: "27px",
      marginRight: "20px",
      marginTop: "27px",
    },
  },
};

export const isBingo = (gameName) => {
  return ["ruslotto", "gzhl", "bingo75", "zp", "6x36"].includes(gameName);
};
