export var fontSizes = [
    'var(--plasma-typo-fontSizes-0, 0.625rem)',
    'var(--plasma-typo-fontSizes-1, 0.75rem)',
    'var(--plasma-typo-fontSizes-2, 0.875rem)',
    'var(--plasma-typo-fontSizes-3, 1rem)',
    'var(--plasma-typo-fontSizes-4, 1.25rem)',
    'var(--plasma-typo-fontSizes-5, 1.5rem)',
    'var(--plasma-typo-fontSizes-6, 2rem)',
    'var(--plasma-typo-fontSizes-7, 3rem)',
    'var(--plasma-typo-fontSizes-8, 3.75rem)',
    'var(--plasma-typo-fontSizes-9, 6rem)'
];
