import React from "react";

import { Tabs } from "@sberdevices/plasma-ui";

import { isTouch } from "../../../../helpers/ScreenUtils";

import {
  Top3GameSettingsRulesTextBoxSubTitle,
  Top3GameSettingsRulesTextBox,
  Top3GameSettingsRulesCol,
  Top3GameSettingsTabItemRulesPanel,
} from "./styles/top3-styles";

class Top3GameSettingsControlPanel extends React.Component {
  getTop3GameSettingControlPanel() {
    let rulesList = [];

    for (let currentRule in this.props.top3RulesList) {
      rulesList.push(
        <Top3GameSettingsTabItemRulesPanel
          tabIndex={this.props.messagePopupWasOpened || isTouch() ? -1 : 0}
          size={"s"}
          key={currentRule}
          id={this.props.idTicket}
          settingsSelected={currentRule === this.props.gameSetting}
          ticketWaiting={
            this.props?.ticketWaiting && currentRule === this.props.gameSetting
          }
          onClick={() => {
            this.ticketRuleControl(currentRule);
          }}
        >
          <div style={{ lineHeight: "18px !important" }}>
            <p>{this.props.top3RulesList[currentRule].rusName}</p>
          </div>
        </Top3GameSettingsTabItemRulesPanel>
      );
    }

    return <div>{rulesList}</div>;
  }

  ticketRuleControl(currentRule) {
    if (!this.props?.ticketWaiting) {
      this.props.editTicketRule(currentRule);
    }
  }

  render() {
    return (
      <Top3GameSettingsRulesCol id={this.props.idTicket}>
        <Tabs
          id={this.props.idTicket}
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.12)",
            display: "contents",
          }}
        >
          {
            <Top3GameSettingsTabItemRulesPanel
              isFirstItem={true}
              tabIndex={-1}
              id={this.props.idTicket}
            >
              {" "}
              {!this.props?.ticketWaiting
                ? "Выберите тип игры:"
                : "Выбран тип игры:"}
            </Top3GameSettingsTabItemRulesPanel>
          }
          {this.getTop3GameSettingControlPanel()}

          <Top3GameSettingsRulesTextBox id={this.props.idTicket}>
            <Top3GameSettingsRulesTextBoxSubTitle>
              {this.props.top3RulesList[this.props.gameSetting].gameRulesText}
            </Top3GameSettingsRulesTextBoxSubTitle>
          </Top3GameSettingsRulesTextBox>
        </Tabs>
      </Top3GameSettingsRulesCol>
    );
  }
}
export default Top3GameSettingsControlPanel;
